import {Link} from "react-router-dom";

export const results = {
    management: 0,
    creative:  0,
    education: 0,
    risk: 0,
    master: 0,
}
export const questions = [
    {
        question: "Что для вас важнее - процесс или результат?",
        answers: [
            {
                answer: "Процесс",
                counts: {
                    management: +1,
                }
            },
            {
                answer: "Результат",
                counts: {
                    creative: +1,
                    education: +1,
                }
            },
        ]
    },
    {
        question: "Часто ли вы жалеете свои силы для достижения целей?",
        answers: [
            {
                answer: "Да ",
                counts: {
                    management: -1,
                    creative: -1,
                    risk: -1
                }
            },
            {
                answer: "Результат",
                counts: {
                    management: +1,
                    creative: +1,
                    risk: +1
                }
            },
        ]
    },
    {
        question: "Довольны ли вы тем, что имеете?",
        answers: [
            {
                answer: "Да ",
                counts: {
                    education: +1,
                }
            },
            {
                answer: "Нет",
                counts: {
                    risk: +1
                }
            },
        ]
    },
    {
        question: "Предположим, что, играя в игру, вы можете все выиграть или все потерять. Станете ли вы играть в нее?",
        answers: [
            {
                answer: "Да ",
                counts: {
                    risk: +1,
                }
            },
            {
                answer: "Нет ",
                counts: {
                    master: +1
                }
            },
        ]
    },
    {
        question: "Вы обычно долго решаетесь делать то, что необходимо, но вам не нравится?",
        answers: [
            {
                answer: "Да ",
                counts: {
                    master: +1,
                    creative: +1,
                }
            },
            {
                answer: "Нет ",
                counts: {
                    management: +1
                }
            },
        ]
    },
    {
        question: "Оказавшись в компании знакомых, любите ли вы находиться в центре внимания?",
        answers: [
            {
                answer: "Да ",
                counts: {
                    creative: +1,
                    education: +1,
                }
            },
            {
                answer: "Нет ",
                counts: {
                    creative: -1,
                    education: -1,
                }
            },
        ]
    },
    {
        question: "Оказавшись в шумной незнакомой компании, как вы себя чувствуете?\n",
        answers: [
            {
                answer: "Вполне комфортно",
                counts: {
                    management: +1,
                    education: +1,
                }
            },
            {
                answer: "Скорее некомфортно",
                counts: {
                    management: -1,
                }
            },
        ]
    },
    {
        question: "Вам тяжело дается монотонная работа?",
        answers: [
            {
                answer: "Да",
                counts: {
                    risk: +1,
                }
            },
            {
                answer: "Нет, легко",
                counts: {
                    master: +1,
                    education: +1
                }
            },
        ]
    },
    {
        question: "Существует ли у вас цель поделиться своими знаниями и навыками с другими людьми? К примеру, с последующими поколениями?",
        answers: [
            {
                answer: "Да, такая цель существует",
                counts: {
                    education: +1,
                }
            },
            {
                answer: "Скорее нет, у меня другие приоритеты",
                counts: {
                    management: +1,
                }
            },
        ]
    },
    {
        question: "Есть ли у вас проблемы с концентрацией?",
        answers: [
            {
                answer: "Есть",
                counts: {
                    creative: +1,
                }
            },
            {
                answer: "Нет ",
                counts: {
                    master: +1,
                }
            },
        ]
    },

    //11
    {
        question: "Каким из перечисленных добродетелей вы обладаете в большей степени?\n",
        answers: [
            {
                answer: "Самоотверженность ",
                counts: {
                    management: +1,
                }
            },
            {
                answer: "Сила воли ",
                counts: {
                    risk: +1,
                }
            },
            {
                answer: "Рассудительность ",
                counts: {
                    education: +1,
                }
            },
            {
                answer: "Работоспособность  ",
                counts: {
                    master: +1,
                }
            },
        ]
    },
    {
        question: " Каким качеством в окружающих людях вы больше всего дорожите - деятельностью и отзывчивостью?",
        answers: [
            {
                answer: "Деятельность  ",
                counts: {
                    management: +1,
                }
            },
            {
                answer: "Отзывчивость  ",
                counts: {
                    risk: +1,
                }
            },
        ]
    },
    {
        question: "Может ли вас заинтересовать игра, где нет выигрыша?\n",
        answers: [
            {
                answer: "Да, может",
                counts: {
                    master: +1,
                }
            },
            {
                answer: "Нет, не может",
                counts: {
                    risk: +1,
                }
            },
        ]
    },
    {
        question: "Вы часто завершаете работу вопреки окружающим помехам, нехватке времени и тд?",
        answers: [
            {
                answer: "Да, часто завершаю",
                counts: {
                    creative: +1,
                    master: +1,
                }
            },
            {
                answer: "Нет, редко завершаю в таких условиях",
                counts: {
                    education: +1,
                }
            },
        ]
    },
    {
        question: "Любите ли вы начинать работать над несколькими делами одновременно или вы предпочитаете сконцентрироваться на одном?",
        answers: [
            {
                answer: "Предпочитаю работать одновременно над несколькими делами ",
                counts: {
                    risk: +1,
                }
            },
            {
                answer: "Предпочитаю концентрироваться на одном деле",
                counts: {
                    master: +1,
                }
            },
        ]
    },
    {
        question: "Бывает так, что вы, увлекшись деталями, не успеваете довести дело до конца?",
        answers: [
            {
                answer: "Да, бывает ",
                counts: {
                    risk: +1,
                }
            },
            {
                answer: "Нет, я всегда довожу дело до конца",
                counts: {
                    master: +1,
                }
            },
        ]
    },
    {
        question: "Как вы любите проводить свободное время?",
        answers: [
            {
                answer: "Общаться с друзьями или близкими людьми",
                counts: {
                    management: -1,
                }
            },
            {
                answer: "Читать книги",
                counts: {
                    education: +1,
                    creative: +1
                }
            },
            {
                answer: "Играть в компьютерные игры",
                counts: {
                    education: -1,
                }
            },
            {
                answer: "Смотреть фильмы и сериалы",
                counts: {
                    master: -1,
                }
            },
        ]
    },
    {
        question: "Вам трудно решиться использовать усилия человека для своих интересов?\n",
        answers: [
            {
                answer: "Да, трудно",
                counts: {
                    education: +1,
                }
            },
            {
                answer: "Нет, не трудно",
                counts: {
                    management: +1,
                }
            },
        ]
    },
    {
        question: "Соглашаясь на дело, вы думаете о том, насколько оно вам интересно?",
        answers: [
            {
                answer: "Да. Интерес - один из главных факторов",
                counts: {
                    creative: +1,
                }
            },
            {
                answer: "Нет, это не имеет особого значения",
                counts: {
                    management: +1,
                }
            },
        ]
    },
    {
        question: "С каким видом информации вы работаете лучше всего?",
        answers: [
            {
                answer: "Текст ",
                counts: {
                    education: +1,
                    master: -1,
                }
            },
            {
                answer: "Аудио ",
                counts: {
                    management: +1,
                }
            },
            {
                answer: "Инфографика и схемы ",
                counts: {
                }
            },
            {
                answer: "Предпочитаю работать с информацией только на практике своего дела",
                counts: {
                    master: +1,
                }
            },
        ]
    },

    //21
    {
        question: "Часто ли вы погружаетесь в работу так сильно, что не замечаете течения времени?",
        answers: [
            {
                answer: "Да  ",
                counts: {
                    master: +1,
                    creative: +1,
                    risk: +1
                }
            },
            {
                answer: "Нет  ",
                counts: {
                    management: +1,
                    education: +1
                }
            },
        ]
    },
    {
        question: "Сложно ли вам находиться в конфликтных стрессовых ситуациях?",
        answers: [
            {
                answer: "Да ",
                counts: {
                    creative: +1,
                }
            },
            {
                answer: "Нет ",
                counts: {
                    management: +1,
                    risk: +1
                }
            },
        ]
    },
    {
        question: "Что для вас важнее - оставить след в истории или в своей семье?\n",
        answers: [
            {
                answer: "След в истории",
                counts: {
                    creative: +1,
                    management: +1,
                    risk: +1
                }
            },
            {
                answer: "Оставить наследие семье",
                counts: {
                    risk: -1,
                    master: +1
                }
            },
        ]
    },
    {
        question: "Как вы оцениваете свою способность к обучению? Выберите наиболее подходящий вариант.",
        answers: [
            {
                answer: "Легко обучаюсь чему-то новому",
                counts: {
                    education: +1,
                    risk: +1
                }
            },
            {
                answer: "Люблю погружаться только в какую-то конкретную область",
                counts: {
                    creative: +1,
                }
            },
            {
                answer: "Мне не подходят большинство видов обучения. Я учусь только на практике, по ходу занятия делом",
                counts: {
                    master: +1,
                }
            },
        ]
    },
    {
        question: "Что самое главное в жизни для вас?",
        answers: [
            {
                answer: "Карьера ",
                counts: {
                    creative: -1,
                }
            },
            {
                answer: "Семья ",
                counts: {
                    risk: -1,
                }
            },
            {
                answer: "Изменение общества и окружающего мира к лучшему",
                counts: {
                    education: +1,
                }
            },
            {
                answer: "Ничего из перечисленного - только свои собственные цели",
                counts: {
                    education: -1,
                    master: -1
                }
            },
        ]
    },
]

const asideMirrorLink = "/courses/aside-mirror/"

export const testResults = {
    creative: {
        title: "Творческая сфера",
        content: (
            <>
                <p>
                    Вы - человек искусства. Вы будете счастливы в том случае, если творчество станет самим образом вашей жизни. Вы можете найти себя в разных областях искусства - быть художником, музыкантом, актером, писателем, дизайнером и даже учителем искусства. Важно, чтобы ваша работа позволяла вам выражать свою творческую искру и делиться ею с миром.
                </p>
                <p>
                    Важно помнить, что жизнь художника может быть полна вызовов. Путь к успеху может быть непредсказуемым, и иногда нужно будет преодолевать трудности. Но ваша страсть к искусству и творчеству всегда будет вашим главным двигателем. Для того, чтобы облегчить ваш путь, помните о двух вещах:
                </p>
                <ul>
                    <li>
                        Не стоит пренебрегать фундаментальным образованием. Оно поможет вам глубже понимать искусство, а также очаровывать окружающих людей нетривиальным ходом ваших мыслей.
                    </li>
                    <li>
                        Изучайте технологии. История искусства - это, по сути, история технологий, которые помогли создать великие шедевры, изменившие облик мирового искусства. В современном же мире очень важно быть в курсе возможностей современных технологий, которые предоставляются современным деятелям искусства.
                    </li>
                </ul>

                <p>
                    Полезные знания позволят вам решить любую возникающую перед вами задачу. И тогда уже ничто не помешает вам смотреть на мир как на полотно, на котором можно реализовывать ваш собственный шедевр.
                </p>

                <h4>Специальные предложения для вас</h4>
                <p>
                    Курс <Link to={"/courses/picture-by-digit"}>“Картина цифрой”</Link>, курс  <Link to={"/courses/art-of-design-and-architecture"}>“Искусство дизайна и архитектуры”</Link>
                </p>
            </>
        )
    },
    risk:  {
        title: "Сфера высоких рисков",
        content: (
            <>
                <p>
                    Вы склонны к риску. Вы чувствуете себя естественно в хаотической среде и располагаете необходимой силой для того, чтобы управлять этим хаосом. К такого рода деятельности относятся предпринимательство, финансовая сфера, работа на себя в различных проявлениях. К примеру, военное дело тоже относится к этому направлению. Находчивость, живой ум и активность - вот что здесь является залогом успеха.
                </p>
                <p>
                    Многие пытаются убедить нас, что зона высоких рисков имеет свои непреодолимые недостатки и, отчасти, это справедливо, но только не для людей вашего типа. Овладев вышеперечисленными добродетелями, вы сможете держаться на плаву столько, сколько вам будет необходимо.
                </p>
                <p>
                    Самое главное - не пренебрегайте обучением. Зона высоких рисков - это зона повышенной свободы и ответственности, в которой вы предоставлены самому себе. Знания в самых разных областях и умение вовремя их применить будет залогом вашего успеха.

                </p>

                <h4>Специальные предложения для вас</h4>
                <p>
                    Курс <Link to={"/courses/busines-in-the-era-of-smart-technologies"}>“Бизнес в эпоху умных технологий”</Link>, курс  <Link to={"/courses/cubed-economy"}>“Экономика в кубе”</Link>
                </p>
            </>
        )
    },
    management: {
        title: "Сфера управления людьми",
        content: (
            <>
                <p>
                    Вы хорошо работаете с людьми и у вас есть определенные навыки для того, чтобы воздействовать на них. Это означает, что вам легко дается командная работа, ведь вам вполне естественно дается приводить социальную систему к движению. Вы можете раскрыться как хороший администратор, управленец, политик или лидер какой-либо группы.                </p>
                <p>
                    Вам очень повезло - люди такого типа всегда найдут себе место, ведь мы живем в социальном мире, где навык работы с людьми и эффективное управления группами востребован всегда. Самое главное - помните о ваших людей, заботьтесь о них. Лояльные люди - это те, кто поможет вам достичь самых вершин.
                </p>
                <p>
                    Кроме того, не пренебрегайте изучением философии и истории древних традиций, они помогут вам стать самым лучшим управленцем. Вы созданы для того, чтобы менять судьбы людей, а это большая ответственность. Лишь образованные люди, разбирающиеся в этике, философии, способные видеть причины сложных явлений и предугадывать последствия, станут по-настоящему легендарными лидерами.
                </p>

                <h4>Специальные предложения для вас</h4>
                <p>
                    Раскрытием своего профессионального потенциала и определением наилучшего жизненного сценария мы занимаемся на нашем крупном курсе  <Link to={asideMirrorLink}>“По ту сторону зеркала”</Link>. Получите крупную скидку на него, просто перейдя по ссылке.
                </p>
            </>
        )
    },
    education: {
        title: "Сфера образования",
        content: (
            <>
                <p>
                    Вы являетесь прирожденным учителем. Ваше искусство - передавать знания и навыки другим людям. Быть учителем - это нечто большее, чем профессия. Это стиль жизни, благородный путь, сопряженный, тем не менее, с некоторыми трудностями. Дело в том, что не каждый хороший специалист является хорошим педагогом, но каждый хороший педагог является в том числе и хорошим специалистом. Посмотрите на свой опыт, на свои знания и интересы. Что вас интересует в жизни? - Все это вы можете передать другим.
                </p>
                <p>
                    Не пренебрегайте получением маркетинговыми компетенциями. Живя в эпоху цифровых технологий, у учителей (в самом широком смысле этого слова) появились замечательные возможности для продвижения своей деятельности. Научитесь пользоваться этим и вы не останетесь без дела, ведь процесс передачи знаний является фундаментальным социальным процессом, спрос на который не иссякнет никогда.
                </p>
                <p>
                    Кроме того, не пренебрегайте изучением философии и истории древних традиций, они помогут вам стать самым лучшим управленцем. Вы созданы для того, чтобы менять судьбы людей, а это большая ответственность. Лишь образованные люди, разбирающиеся в этике, философии, способные видеть причины сложных явлений и предугадывать последствия, станут по-настоящему легендарными лидерами.
                </p>

                <h4>Специальные предложения для вас</h4>
                <p>
                    Раскрытием своего профессионального потенциала и определением наилучшего жизненного сценария мы занимаемся на нашем крупном курсе  <Link to={asideMirrorLink}>“По ту сторону зеркала”</Link>. Получите крупную скидку на него, просто перейдя по ссылке.
                </p>
            </>
        )
    },
    master: {
        title: "Мастер своего дела",
        content: (
            <>
                <p>
                    Это очень интересный тип. Вы обладаете большим талантом к кропотливой работе. В каком-то смысле это творческий путь, но при этом требующий постоянного повышения своего мастерства. Вы не очень склонны к риску, вряд ли любите хаос и занятия организаторскими делами.
                </p>
                <p>
                    Люди такого типа прекрасно реализуются в отраслевых профессиональных областях - в тех местах, которые требуют большого погружения и постоянного совершенствования своего мастерства. Такие люди становятся потрясающими инженерами, архитекторами, дизайнерами, IT-специалистами, ювелирами и ремесленниками. Выбирайте область согласно вашему предпочтению и помните, что любой инструмент в ваших руках становится средством создания чего-то сложного и совершенного.
                </p>
                <p>
                    Напоследок, примите совет от лица команды (non)human - открывайте новые грани вашей профессии и постоянно совершенствуйтесь в ее владении. Но перед этим нужно разрешить главный вопрос - найти любимое дело, ту область, погружаясь в которую вы сможете реализовать весь свой потенциал. Обратите внимание на то, что у вас хорошо получается, чем вам нравится заниматься, чем занимались ваши предки. Все это поможет определить ваш путь, на котором вы станете настоящим мастером своего дела.
                </p>

                <h4>Специальные предложения для вас</h4>
                <p>
                    Раскрытием своего профессионального потенциала и определением наилучшего жизненного сценария мы занимаемся на нашем крупном курсе  <Link to={asideMirrorLink}>“По ту сторону зеркала”</Link>. Получите крупную скидку на него, просто перейдя по ссылке.
                </p>
            </>
        )
    }
}

export const ANSWERS_KEY = "answers"
export const COUNTS_KEY = "counts"
export const NEED_RETURN_TO_TEST_KEY = "NEED_RETURN_TO_TEST_KEY"