import React, {useEffect, useState} from 'react';
import Mrgn from "../../layout/Mrgn";
import ListSubscribes from "./components/ListSubscribes";
import {buyTinkoffSubscribe, checkIsSubscribe, getAllCoursesAllowSubscribe} from "../../service/api/subscribeApi";
import CourseCard from "../../stories/CourseCard";
import {ICoursesPage, ISubscribe} from "../../service/types/interfaces";
import Loader from "../../components/loader/loader";
import useGetUser from "../../service/hooks/useGetUser";
import FormRegister from "../../stories/FormRegister";
import BuySubscribe from "./components/BuySubscribe";
import ReviewsCarousel from "../../widgets/reviews/ReviewsCarousel";
import SubscribeInfo from "./components/SubscribeInfo";

const Subscription = () => {
    const [data, setData] = useState<any>(undefined)
    const [isSubscribe, setIsSubscribe] = useState<boolean | null>(null)
    const [subscribe, setSubscribe] = useState<ISubscribe | null>(null)
    const [user] = useGetUser();

    useEffect(() => {
        checkIsSubscribe().then((res) => setIsSubscribe(res))
    }, [])

    useEffect(() => {
        getAllCoursesAllowSubscribe().then(res => setData(res))
    }, [])

    const coursesMeow = data !== null && data?.map((element: ICoursesPage) => <CourseCard element={element}/>);

    const buySubscribe = async () => {
        const response = await buyTinkoffSubscribe(
            localStorage.getItem("token"),
            subscribe?.subscribeId ?? 0
        );
        if (response?.data) {
            if (response.data) {
                window.location.replace(response.data);
            }
        }
    };

    return (
        <>
            <Mrgn/>
            {isSubscribe === null ? <Loader/> :
                <div>
                    {!isSubscribe && (
                        <>
                            <ListSubscribes subscribe={subscribe} setSubscribe={setSubscribe}/>
                            <SubscribeInfo subscribe={subscribe}/>
                            {
                                !user ? <FormRegister courseCurrentPrice={subscribe?.currentPrice}
                                                      buyFunc={buySubscribe}/>
                                    : <BuySubscribe subscribe={subscribe}/>
                            }
                        </>)
                    }

                    <div>
                        <h3 className="text-xl text-center mt-16 mb-8 md:text-2xl ">
                            Доступные курсы по подписке
                        </h3>
                        <div className="courses-page">
                            <div className="courses-container">{coursesMeow}</div>
                        </div>

                        <ReviewsCarousel maxCount={6} folder={"subscribe"}
                                         reviews={[
                                             "../../reviews/subscribe/1.png",
                                             "../../reviews/subscribe/2.jpg",
                                             "../../reviews/subscribe/3.png",
                                             "../../reviews/subscribe/4.png",
                                             "../../reviews/subscribe/5.png",
                                             "../../reviews/subscribe/6.png",
                                         ]}
                        />
                    </div>
                </div>
            }
        </>

    );
};

export default Subscription;
