import React, {createContext, useEffect, useState} from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }: any) => {
	const [auth, setAuth] = useState({});
	useEffect(() => {
		if (localStorage.getItem("token")) {
			setAuth({
				token: `${localStorage.getItem("token")}`,
				refreshToken: `${localStorage.getItem("refreshToken")}`,
			});
		}
	}, []);

	return (
		<AuthContext.Provider value={{ auth, setAuth }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
