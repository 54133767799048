import React from 'react';
import {TemplateLanding, TypeLanding} from "../widgets/landing/TemplateLanding";
import {Helmet} from "react-helmet";

export const WelcomeChannelTwo = () => {

    const data = {
        title: "Телеграм-канал, который расскажет про практическую психологию все что вам нужно",
        array: ["Как работает человеческая психика?", "Как распознать манипуляции?", "Как убедить собеседника во всем что вам необходимо?", "Как читать людей?"],
        type: TypeLanding.CHANNEL,
        link: "https://t.me/+aP-Pjhdr4zhiMGMy"
    }

    return (
        <>
            <Helmet>
                <script type="text/javascript">
                    {`
         (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(98309295, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true
   });
                    `}
                </script>
                <noscript>
                    {`<div><img src="https://mc.yandex.ru/watch/98309295" style="position:absolute; left:-9999px;" alt="" /></div>`}
                </noscript>
            </Helmet>
            <TemplateLanding {...data}/>
        </>
    );
};
