import React from 'react';
import {COURSES_PATH} from "../../service/const/paths";
import {Link} from "react-router-dom";

const SuccessRegistrationPage = () => {
    return (
        <div
            style={{
                display: "flex",
                height: "100vh",
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <div className="form-container form-login-bg">
                <div style={{margin: '0 32px', marginBottom:32, fontSize:18}}>
                    Поздравляем с успешной регистрацией! Для вашего удобства, пароль для входа был выслан на ваш email.
                </div>

                <div style={{ display:'flex', alignItems:"center", justifyContent:"center"}}>
                    <Link to={COURSES_PATH}>
                    <button className="button">к курсам</button>
                </Link>
                </div>
            </div>
        </div>
    );
};

export default SuccessRegistrationPage;
