import React, {useEffect} from 'react';
import {
    ABOUT_PATH,
    ACCOUNT_PATH,
    ACTOR_NETWORK_THEORY_PATH,
    ADMIN_PANEL_PATH,
    ART_OF_DESIGN_AND_ARCHITECTURE_PATH,
    ARTICLES_ADMIN_PATH,
    ASIDE_MIRROR_PATH,
    ASIDE_MIRROR_PRO_PATH,
    BLOG_ID_PATH,
    BLOGS_PATH,
    BUSINESS_IN_THE_ERA_OF_SMART_TECHNOLOGIES_PATH,
    CITY_OUTSIDE_PATH,
    CONFIRM_EMAIL_PATH,
    COURSES_PATH,
    CREATE_LONGREADS_ADMIN_PATH,
    CRISES_OF_CIVILIZATION_PATH,
    CUBED_ECONOMY_PATH,
    DIGITAL_MAGNATE_PATH,
    FOR_BUSINESS_PATH,
    HOME_PATH,
    HOW_IS_SOCIETY_ORGANIZED_PATH,
    HOW_THE_WORLD_WORKS_PATH,
    LOGIN_PATH,
    LONGREAD_PATH,
    LONGREADS_ADMIN_PATH,
    ARTICLES_PATH,
    NEWS_ADMIN_PATH,
    OFERTA_PATH,
    PICTURE_BY_DIGIT_PATH,
    PRIVACY_POLICY_PATH,
    RADIOS_PATH,
    REFER_PATH,
    REGISTER_PATH,
    RESET_PASSWORD_PATH,
    SECRETS_OF_THE_UNIVERSE,
    SUBSCRIPTION_PATH,
    TEST_PATH,
    UNSUBSCRIBE_PATH,
    VALIDATE_PURCHASE_PATH,
    VALIDATE_SUBSCRIBE_PURCHASE_PATH,
    WAR_AND_UNINTELLIGENT_MACHINES_PATH,
    ARTICLES_ID_PATH,
    NEWS_PATH,
    NEWS_ID_PATH,
    SUCCESS_REGISTER_PATH,
    LANDING_WELCOME_BOT_ONE,
    LANDING_WELCOME_BOT_TWO,
    LANDING_WELCOME_CHANNEL_ONE,
    LANDING_WELCOME_CHANNEL_TWO,
    HOW_READ_PEOPLE_PATH
} from "../const/paths";
import Home from "../../scenes/home/Home";
import LoginPage from "../../scenes/authPages/LoginPage";
import {Route, Routes} from "react-router-dom";
import RegisterPage from "../../scenes/authPages/RegisterPage";
import RequireAuth from "../../layout/RequireAuth";
import Account from "../../scenes/account/Account";
import ConfirmEmail from "../../scenes/account/ConfirmEmail";
import ResetPassword from "../../scenes/account/ResetPassword";
import ValidatePurchase from "../../scenes/account/ValidatePurchase";
import Unsubscribe from "../../scenes/account/Unsubscribe";
import Refer from "../../scenes/account/Refer";
import AboutUs from "../../scenes/about/AboutUs";
import Panel from "../../scenes/panel/Panel";
import LongreadsPanel from "../../scenes/panel/longreads-panel/LongreadsPanel";
import CreateLongread from "../../scenes/panel/CreateLongread";
import ContainerBlogs from "../../scenes/longreads/ContainerBlogs";
import ContainerArticles from "../../scenes/articles/ContainerArticles";
import LongreadPage from "../../stories/longreads/LongreadPage";
import TestPage from "../../scenes/test/TestPage";
import Courses from "../../scenes/courses/Courses";
import BusinesTech from "../../scenes/course/businesTech/BusinesTech";
import Gorodvovne from "../../scenes/course/gorodvovne/Gorodvovne";
import Civilization from "../../scenes/course/civilization/Civilization";
import ByDigit from "../../scenes/course/ByDigit";
import TayniMira from "../../scenes/course/TayniMira";
import NetworkTheory from "../../scenes/course/NetworkTheory";
import HowSocietyWorks from "../../scenes/course/HowSocietyWorks";
import AsideMirror from "../../scenes/course/asideMirror/AsideMirror";
import Privacy from "../../scenes/servicePages/Privacy";
import Oferta from "../../scenes/servicePages/Oferta";
import Subscription from "../../scenes/subscriptions/Subscription";
import ReactGA from "react-ga";
import {IBuildNode} from "../types/interfaces";
import Layout from "../../layout/Layout";
import ValidateSubscribePurchase from "../../scenes/account/ValidateSubscribePurchase";
import NewsPanel from "../../scenes/panel/news-panel/NewsPanel";
import ArticlePanel from "../../scenes/panel/article-panel/ArticlePanel";
import {ContainerNews} from "../../scenes/news/ContainerNews";
import HowWorldWorks from "../../scenes/course/HowWorldWorks";
import SuccessRegistrationPage from "../../scenes/succesRegistrtionPage/SuccessRegistrationPage";
import {WelcomeBotOne} from "../../landings/WelcomeBotOne";
import {WelcomeBotTwo} from "../../landings/WelcomBotTwo";
import {WelcomeChannelOne} from "../../landings/WelcomeChannelOne";
import {WelcomeChannelTwo} from "../../landings/WelcomeChannelTwo";
import {HowReadPeople} from "../../scenes/course/howReadPeople/HowReadPeople";


const ACCOUNT_ELEMENT = (
    <RequireAuth>
        <Account/>
    </RequireAuth>
)

const routing: IBuildNode[] = [
    {path: HOME_PATH, element: <Home/>, isLayout: true},
    {path: LOGIN_PATH, element: <LoginPage/>, isLayout: true},
    {path: REGISTER_PATH, element: <RegisterPage/>, isLayout: true},
    {path: SUBSCRIPTION_PATH, element: <Subscription/>, isLayout: true},
    {path: ACCOUNT_PATH, element: ACCOUNT_ELEMENT, isLayout: true},
    {path: CONFIRM_EMAIL_PATH, element: <ConfirmEmail/>, isLayout: true},
    {path: CONFIRM_EMAIL_PATH, element: <ConfirmEmail/>, isLayout: true},
    {path: RESET_PASSWORD_PATH, element: <ResetPassword/>, isLayout: true},
    {path: VALIDATE_PURCHASE_PATH, element: <ValidatePurchase/>, isLayout: true},
    {path: VALIDATE_SUBSCRIBE_PURCHASE_PATH, element: <ValidateSubscribePurchase/>, isLayout: true},
    {path: UNSUBSCRIBE_PATH, element: <Unsubscribe/>, isLayout: true},
    {path: REFER_PATH, element: <Refer/>, isLayout: true},
    {path: ABOUT_PATH, element: <AboutUs/>, isLayout: true},
    {path: ADMIN_PANEL_PATH, element: <Panel/>, isLayout: true},
    {path: LONGREADS_ADMIN_PATH, element: <LongreadsPanel/>, isLayout: true},
    {path: NEWS_ADMIN_PATH, element: <NewsPanel/>, isLayout: true},
    {path: ARTICLES_ADMIN_PATH, element: <ArticlePanel/>, isLayout: true},
    {path: CREATE_LONGREADS_ADMIN_PATH, element: <CreateLongread/>, isLayout: true},
    {path: SUCCESS_REGISTER_PATH, element: <SuccessRegistrationPage/>, isLayout: true},
    // {path: FOR_BUSINESS_PATH, element: <ForBusiness/>, isLayout: true},


    // Новости
    {path: NEWS_PATH, element: <ContainerNews/>, isLayout: true},
    {path: NEWS_ID_PATH, element: <LongreadPage type={0}/>, isLayout: true},

    // Блог
    {path: BLOGS_PATH, element: <ContainerBlogs/>, isLayout: true},
    {path: BLOG_ID_PATH, element: <LongreadPage type={1}/>, isLayout: true},

    // Издания
    {path: ARTICLES_PATH, element: <ContainerArticles/>, isLayout: true},
    {path: ARTICLES_ID_PATH, element: <LongreadPage type={2}/>, isLayout: true},

    // {path: RADIOS_PATH, element: <PodcastsLectures/>, isLayout: true},
    {path: TEST_PATH, element: <TestPage/>, isLayout: true},
    {path: COURSES_PATH, element: <Courses/>, isLayout: true},
    {path: BUSINESS_IN_THE_ERA_OF_SMART_TECHNOLOGIES_PATH, element: <BusinesTech/>},
    {path: CITY_OUTSIDE_PATH, element: <Gorodvovne/>},
    {path: HOW_READ_PEOPLE_PATH, element: <HowReadPeople/>},
    {path: CRISES_OF_CIVILIZATION_PATH, element: <Civilization/>},
    {path: PICTURE_BY_DIGIT_PATH, element: <ByDigit/>},
    // {path: ART_OF_DESIGN_AND_ARCHITECTURE_PATH, element: <ArtOfDesignAndArchitecture/>},
    // {path: CUBED_ECONOMY_PATH, element: <CubedEconomy/>},
    // {path: WAR_AND_UNINTELLIGENT_MACHINES_PATH, element: <WarAndMachines/>},
    {path: SECRETS_OF_THE_UNIVERSE, element: <TayniMira/>},
    {path: ACTOR_NETWORK_THEORY_PATH, element: <NetworkTheory/>},
    {path: HOW_IS_SOCIETY_ORGANIZED_PATH, element: <HowSocietyWorks/>},
    {path: HOW_THE_WORLD_WORKS_PATH, element: <HowWorldWorks/>},
    // {path: DIGITAL_MAGNATE_PATH, element: <DigitalMagnat/>},
    {path: ASIDE_MIRROR_PATH, element: <AsideMirror/>},
    {path: ASIDE_MIRROR_PRO_PATH, element: <AsideMirror isPro/>},
    {path: PRIVACY_POLICY_PATH, element: <Privacy/>},
    {path: OFERTA_PATH, element: <Oferta/>},

    {path: LANDING_WELCOME_BOT_ONE, element: <WelcomeBotOne/>},
    {path: LANDING_WELCOME_BOT_TWO, element: <WelcomeBotTwo/>},
    {path: LANDING_WELCOME_CHANNEL_ONE, element: <WelcomeChannelOne/>},
    {path: LANDING_WELCOME_CHANNEL_TWO, element: <WelcomeChannelTwo/>}
]

export const Routing = () => {
    const TRACKING_ID = "G-H4Q0QKDYZT";
    ReactGA.initialize(TRACKING_ID);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const buildNode = (el: IBuildNode) => {
        return (
            <>
                {el.isLayout && <Layout/>}
                {el.element}
            </>
        )
    }

    return (
        <Routes>
            {routing.map((el, i) => <Route key={i} path={el.path} element={buildNode(el)}/>)}
        </Routes>
    );
};
