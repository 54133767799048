import React from 'react';

const HTwo = (props: any) => {
    return (
        <h2 style={{
            fontWeight: 300,
            marginBottom: "1em",
            fontSize: "2rem",
            marginInline: "auto",
            textAlign: "center",
            ...props.style
        }}>
            {props.children}
        </h2>
    );
};

export default HTwo;