// 👇️ ts-nocheck disables type checking for entire file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// 👇️ ts-ignore ignores any ts errors on the next line
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

//вырубил тут тайпскрипт, хз на что он тут жаловался, но была жесть какая-то с парамами вроде
import React, {useEffect, useState} from "react";
import rehypeRaw from "rehype-raw";
import {ReactMarkdown} from "react-markdown/lib/react-markdown";
import "../../styles/componentStyles/longread.css";
import Mrgn from "../../layout/Mrgn";
import {useParams} from "react-router-dom";
import {LONGREADS} from "../../scenes/longreads/nonhuman_longreeds";
import {
    getLongreadMarkdown,
    getLongreadRecommendations,
    getNewLongread,
    TLongread,
    TLongreadRecommendation,
} from "../../service/api/longreadsApi";
import LongreadCard from "./LongreadCard";
import {Helmet} from "react-helmet-async";
import {newLongreadLink} from "../../etc/newLongreadLink";
import {getArticleByUrl} from "../../service/api/articlesApi";
import {getNewByUrl} from "../../service/api/newsApi";

function LongreadPage(props) {
    const {type} = props

    let params = useParams();
    let paramsNewInfo = params.url;

    const [newLongread, setNewLongread] = useState<TLongread | null>();
    const [md, setmd] = useState<any>("");
    const [isOld, setIsOld] = useState(false);
    const [recommendations, setRecommendations] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (type === 2) getArticleByUrl(paramsNewInfo).then((res) => setNewLongread(res.data))
        if (type === 1) getNewLongread(paramsNewInfo).then((res) => setNewLongread(res.data))
        if (type === 0) getNewByUrl(paramsNewInfo).then((res) => setNewLongread(res.data))
    }, []);

    useEffect(() => {
        if (newLongread) {
            const get = async () => {
                try {
                    const res = await getLongreadMarkdown(newLongread.pathToMarkDown);
                    setmd(res.data);
                } catch (err) {
                    // console.log(err);
                }
            };
            get();
        }
    }, [newLongread]);

    // get recommendations
    useEffect(() => {
        if (newLongread) {
            const get = async () => {
                try {
                    const res = await getLongreadRecommendations(newLongread.url, type);
                    setRecommendations(res.data);
                } catch (err) {
                    console.log(err);
                }
            };
            get();
        }
    }, [newLongread]);

    const oldLongRead = () => {
        if (!isOld) return "";
        if (isOld) return LONGREADS[`${params.id}`].article_content;
    }

    return (

        <>
            <Mrgn/>
            {!isOld && (
                <>
                    <Helmet>
                        <title>{newLongread?.htmltitle || newLongread?.title || ""}</title>
                        <meta
                            name={"description"}
                            content={
                                newLongread?.htmldescription || newLongread?.description || ""
                            }
                        />
                    </Helmet>
                    <div className="px-4 max-w-[1000px] m-auto">
                        <div className=" mt-10 mb-10 max-w-[1000px] longread-typography longread-layout ">
                            {/* <ReactMarkdown rehypePlugins={[rehypeRaw]}>{resp}</ReactMarkdown> */}
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{md}</ReactMarkdown>
                        </div>
                        <div>
                            <hr className="mt-32 max-w-[1000px] border-zinc-600"/>
                        </div>

                        {recommendations && (
                            <div
                                className="max-w-[1000px] m-auto mt-16 mb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 place-items-start gap-5">

                                {recommendations.map((r: TLongreadRecommendation) => {
                                    const ltype = () => {
                                        if (r.contentType === "Course") {
                                            return "Курс";
                                        } else {
                                            return "Лонгрид";
                                        }
                                    };

                                    const longreadType = ltype();

                                    return (
                                        <LongreadCard
                                            type={longreadType}
                                            to={`../../${newLongreadLink(r.contentUrl)}`} // this is the way.
                                            img={r.contentPicture}
                                            name={r.contentTitle}
                                            key={r.recommendationId}
                                            descr={r.contentDescription}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </>
            )}
            {isOld && (
                <>
                    <div
                        className="longread-container"
                        dangerouslySetInnerHTML={{__html: `${oldLongRead()}`}}
                    ></div>
                </>
            )}
        </>
    );
}

export default LongreadPage;
