import {fixImgSrc, getCoursesPage} from "../../service/api/coursesApi";
import Carousel from "nuka-carousel";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import CourseCard from "../../stories/CourseCard";
import "./style.css"

interface ICoursesPage {
	courseId: number;
	briefDescription: string;
	courseName: string;
	courseUrlid: string;
	currentPrice: any;
	description: string;
	oldPrice: any;
	srcPicturePage: string;
	releaseDate: any;
	srcPictureAdditional1: string;
	SrcPicturePage: string;
}

const CoursesCarousel = () => {
	const [courses, setCourses] = useState<any>([]);

	useEffect(() => {
		const fetchCourses = async () => {
			const responseCourses = await getCoursesPage();
			// console.log(responseCourses);
			if (responseCourses.status === 200) {
				setCourses(responseCourses.data);
			}
		};
		fetchCourses();
	}, []);

	const [maxCount, setMaxCount] = useState(3)


	return (
		<div className="home-courses-container">
			{courses.map((element: ICoursesPage, index: number) => index < maxCount &&
				<CourseCard element={element}/>
			)}
		</div>
	);
};

export default CoursesCarousel;
