import "../../styles/componentStyles/coursesPage/course.css";
import {fixImgSrc, getCoursesPage, getPurchasedBlocks} from "../../service/api/coursesApi";
import React, {useContext, useEffect, useRef, useState} from "react";
import useCourseInfo from "../../service/hooks/useCourseInfo"; //custom hook
import CoursePlayer from "./CoursePlayer";
import AuthContext from "../../service/context/AuthProvider"; //if auth token
import {Link} from "react-router-dom";
import BuyCourse from "./BuyCourse";

import ChatLink from "../../etc/ChatLink";
import ReviewsCarousel from "../../widgets/reviews/ReviewsCarousel";
import useSetMeta from "../../service/hooks/useSetMeta";
import ChatScreen from "./components/ChatScreen";
import {buyTinkoff} from "../../service/api/buyApi";
import FormRegister from "../../stories/FormRegister";

function TayniMira() {
	const ref = useRef<HTMLDivElement>(null)
	const { auth }: any = useContext(AuthContext);

	useSetMeta({
		title: "Онлайн-курс “Тайны мироздания”",
		description: "Откройте для себя все секреты тайных обществ и разберитесь в особенностях эзотерических течений!\n"
	})

	const buyCourse = async () => {
		if (!localStorage.getItem("token")) {
			if (ref?.current) {
				ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
			}
		} else {
			const response = await buyTinkoff(
				localStorage.getItem("token"),
				COURSEURL
			);
			if (response?.data) {
				if (response.data) {
					window.location.replace(response.data);
				}
			}
		}
	};

	// console.log(showPlayer);
	const COURSEURL = "secrets-of-the-universe"; //* CHANGE THIS WHEN COPY PASTING
	const LATIN = [
		"I",
		"II",
		"III",
		"IV",
		"V",
		"VI",
		"VII",
		"VIII",
		"IX",
		"X",
		"XI",
		"XII",
		"XIII",
		"XIV",
		"XV",
	];
	const [courses, setCourses] = useState<ICoursesPage | []>([]);
	const [course, setCourse] = useState<any>({});
	interface ICoursesPage {
		courseId: number;
		courseName: string;
		courseUrlid: string;
		currentPrice: any;
		description: string;
		oldPrice: any;
		srcPicturePage: string;
	}
	useEffect(() => {
		const fetchCourses = async () => {
			const responseCourses = await getCoursesPage();
			// console.log(responseCourses);
			if (responseCourses.status === 200) {
				setCourses(responseCourses.data);
				setCourse(
					await responseCourses.data.filter((course: ICoursesPage) => {
						return course.courseUrlid === COURSEURL;
					})[0]
				);
			}
		};
		fetchCourses();
	}, []);

	// console.log(course);

	//* getting button names and info (not content)
	const newBItem0 = {
		blockName: "Основы эзотеризма",
		i: [
			{
				blockInfoBlockInfoId: 0,
				blockInfoBlockInfoName: "Что такое эзотерика? В чем специфика эзотерического мировоззрения?",
			},
			{
				blockInfoBlockInfoId: 1,
				blockInfoBlockInfoName: "Отличия эзотеризма от мистики, магии и оккультизма.",
			},
			{
				blockInfoBlockInfoId: 2,
				blockInfoBlockInfoName: "Эзотеризм как внутренняя, тайная часть мировых религий.",
			},
		]

	}

	const newBItem = {
		blockName: "Основа эзотерического мировоззрения",
		i: [
			{
				blockInfoBlockInfoId: 0,
				blockInfoBlockInfoName: "На чем стоит мировой эзотеризм?",
			},
			{
				blockInfoBlockInfoId: 1,
				blockInfoBlockInfoName: "Что такое подлинная эзотерика?",
			},
			{
				blockInfoBlockInfoId: 2,
				blockInfoBlockInfoName: "Феномен инициации – что это такое и зачем ее проходят?",
			},
			{
				blockInfoBlockInfoId: 3,
				blockInfoBlockInfoName: "Почему эзотеризм настолько важен?",
			}
		]

	}
	const [courseInfo, setCourseInfo] = useCourseInfo(COURSEURL);
	useEffect(() => {
		if (courseInfo?.p && courseInfo?.p?.b[2].blockName !== newBItem.blockName) {
			const p = courseInfo?.p
			setCourseInfo({...courseInfo, p: {...p, b: [newBItem0, p.b[1], newBItem, p.b[2]]}})
		}
	}, [courseInfo]);

	// console.log(courseInfo);

	//*purchased button

	const [purchasedBlocks, setPurchasedBlocks] = useState<any>(null);

	useEffect(() => {
		const fetchPurchasedBlocks = async () => {
			if (localStorage.getItem("token")) {
				//!only if autherized
				const response = await getPurchasedBlocks(
					localStorage.getItem("token"),
					COURSEURL
				);
				if (response.data.error === "Курс не был куплен") {
					setPurchasedBlocks(null);
					return;
				}
				if (response.data) {
					setPurchasedBlocks(response.data);
					// console.log(response.data);
				}
			} else return;
		};
		fetchPurchasedBlocks();
	}, []);

	let showPlayer = false;
	if (auth && localStorage.getItem("token") && purchasedBlocks) {
		showPlayer = true;
	}

	function CourseLanding() {
		return (
			<>
				<div className="course-description">
					<p className="descr-string">Описание курса</p>
					<p className="descr-text-main-1">
						В рамках курса мы подробно раскроем историю мистических течений,
						их идеи, структуру и связь с мировыми религиями. Хотите знать все
						секреты тайных обществ? - Тогда этот курс точно для вас
					</p>
					<div className="descr-mid-container">
						{/* <div className="descr-how-many">
								<h2>6</h2>
								<p>практических лекций</p>
							</div> */}
						<div className="descr-who-container">
							<div className="descr-who">
								<img src="../faces/alena_kuzmina.jpg" alt="" />
								<div>
									<p className="descr-who-reads">Специальный гость проекта</p>
									<h2 className="descr-who-name">АЛЕНА КУЗЬМИНА</h2>
									<p className="descr-who-job">
										Эксперт по астрологии, телеведущая
									</p>
								</div>
							</div>
							<div className="descr-who">
								<img src="../faces/pahamov.jpg" alt="" />
								<div>
									<p className="descr-who-reads">Специальный гость проекта</p>
									<h2 className="descr-who-name">Сергей Пахомов</h2>
									<p className="descr-who-job">
										Кандидат философских наук, преподаватель РГПУ им. Герцена,
										автор магистерской программы «Мистико-эзотерические
										течения».
									</p>
								</div>
							</div>
						</div>
					</div>

					<p className="descr-what-you-learn-p">Что вы узнаете на курсе?</p>
					<div className="descr-what-you-learn-container">
						<div className="descr-what-you-learn-text">
							<p>Историю самых значимых эзотерических и оккультных школ</p>
						</div>
						<div className="descr-what-you-learn-text">
							<p>
								Что такое мистицизм и в чем специфика магического
								мировоззрения
							</p>
						</div>
						<div className="descr-what-you-learn-text">
							<p>Связь мировых элит с эзотерикой</p>
						</div>
					</div>
					<div className="course-contains-container">
						<p className="what-course-contains-text">курс состоит из:</p>
						<div className="course-contains-grid-2items">
							<div className="course-contains-item">
								{/* <img src="../../digit/20.png" alt="" />
									 */}
								<h2>300</h2>
								<p>минут видеоматериала</p>
							</div>
							<div className="course-contains-item">
								<img className="a-text" src="../../digit/Atext.png" alt="" />
								<p>тексты,</p>
								<p className="thin">мануалы и схемы</p>
							</div>
						</div>
					</div>
				</div>

				<ChatScreen type={"fil"}/>

				<div className="course-program">
					<h2>Программа курса</h2>
					<div className="course-program-blocks">
						{courseInfo.p ? (
							courseInfo.p.b.map((element: any, index: any) => {
								return (
									<div key={element["blockId"]}>
										<h3 className="block-name">
											{LATIN[index]}&nbsp;&nbsp;&nbsp;
											{element["blockName"]}
										</h3>
										<div className="subblocks">
											{element.i.map((elementInner: any) => {
												if (!elementInner) return null
												return (
													<p key={elementInner["blockInfoBlockInfoId"]}>
														— {elementInner["blockInfoBlockInfoName"]}
													</p>
												);
											})}
										</div>
									</div>
								);
							})
						) : (
							<div>Загрузка...</div>
						)}
					</div>
				</div>
				<div className="buy-course-end">
					<BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
				</div>

				<ReviewsCarousel  folder={"tayni"} maxCount={3}
								  reviews={[
									  "../../reviews/tayni/2.png",
									  "../../reviews/tayni/1.png",
									  "../../reviews/tayni/3.png",
								  ]}
				/>
			</>
		)
	}

	return (
		<>
			{/* <Mrgn /> */}
			<div className="course-nav">
				<Link to="/">
					<img width="150px" height="85px" src="../../logo.png" alt="" />
				</Link>
			</div>
			{course.courseId ? (
				<div className="course-container">
					<div
						className="course-hero"
						style={{
							backgroundImage: `url(${fixImgSrc(
								"files/course-pictures/secrets-of-the-universe.jpg"
							)})`,
						}}
					>
						<div className="course-hero-info">
							<p className="online-course-text">Онлайн курс</p>

							<h1>{course.courseName.toUpperCase()}</h1>
							<BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
						</div>
					</div>
					{
						!localStorage.getItem("token") &&
						<div style={{marginTop: 32}} ref={ref}>
							<div  className="p-2 text-center my-4 md:max-w-[1000px] m-auto">
								Для приобритения курса зарегестрируйтесь на сайте
							</div>
							<FormRegister discount={courseInfo?.p?.courseOldPrice}
										  courseOldPrice={courseInfo?.p?.courseOldPrice}
										  courseCurrentPrice={courseInfo?.p?.courseCurrentPrice}
										  buyFunc={buyCourse}/>
						</div>
					}
					{showPlayer && (
						<>
							<CoursePlayer
								purchasedBlocks={purchasedBlocks}
								courseURLId={COURSEURL}
							/>
							<ChatLink
								chatName="Чат “Оккультный (non)human”"
								chatLink="https://t.me/+NCo3MnTQxogxZTI6"
							/>
						</>
					)}

					{!showPlayer && <CourseLanding/>}
				</div>
			) : (
				<div></div>
			)}
		</>
	);
}

export default TayniMira;
