import React, {useContext, useEffect, useState} from "react";
import Mrgn from "../../layout/Mrgn";
import useGetUser from "../../service/hooks/useGetUser";
import AuthContext from "../../service/context/AuthProvider";
import {getBoughtCourses} from "../../service/api/coursesApi";
import {Link} from "react-router-dom";
import "../../styles/componentStyles/account/account.css";
import Footer from "../../layout/Footer";
import {generateReferLink} from "../../service/api/authApi";
//@ts-expect-error
import story from "./story.pdf";
import {API} from "../../service/const/api";
import InfoChip from "../../components/InfoChip";

function Account() {
    // const [user] = useGetUser();
    const [refer, setRefer] = useState<null | string>(null);
    const [isCopied, setIsCopied] = useState(false);
    const {auth, setAuth}: any = useContext(AuthContext); // using context

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("refer");
        setAuth({});
    };
    const [user] = useGetUser();

    const [boughtCourseres, setBoughtCourses] = useState<any>([]);

    useEffect(() => {
        const fetchBoughtCourses = async () => {
            const response = await getBoughtCourses();
            if (response?.status === 200) {
                setBoughtCourses(response.data);
            }
        };
        fetchBoughtCourses();
    }, []);

    useEffect(() => {
        const fetchRefer = async () => {
            const response = await generateReferLink();
            if (response.status === 200) {
                setRefer(response.data);
            }
        };
        fetchRefer();
    }, []);

    const handleCopyClick = () => {
        if (refer) {
            navigator.clipboard.writeText(refer);
            setIsCopied(true);
        }
    };

    return (
        <>
            <Mrgn/>
            <div className="account-container">
                <div className="account-up-bar">
                    <h2 className="account-name">{user && user?.name}</h2>
                    <div className="account-exit" onClick={logout}>
                        <img src="../../exit.png" alt=""/>
                        <p>Выйти</p>
                    </div>
                </div>

                <h2>Доступные курсы/подкасты:</h2>
                <div className="bought-courses-container">
                    {boughtCourseres ? (
                        boughtCourseres.map((element: any, i: number) =>
                            <Link
                                key={element.userId}
                                className="account-bought-courses"
                                to={`/courses/${element.courseUrlid}`}
                            >
                                <img src={`${API}/${element.srcPicturePersonalArea}`} alt=""/>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: 16
                                }}>
                                    <div>{element.courseName}</div>
                                    {/*<InfoChip style={{border:i % 2 === 0 ? "1px solid #FDE767" : "1px solid white"}}>*/}
                                    {/*    {i % 2 === 0 ? "ПРОДВИНУТЫЙ" : "БАЗОВЫЙ"}*/}
                                    {/*</InfoChip>*/}
                                </div>
                                <p style={{fontSize: 12, marginTop: 16, fontStyle: "italic"}}>
                                    {element.description}
                                </p>
                            </Link>
                        )
                    ) : (
                        <p>У Вас пока нет купленных курсов или подкастов.</p>
                    )}
                </div>
                <div className="refer"
                     onClick={handleCopyClick}
                     style={{marginBottom: "10px"}}
                >
                    <p>{isCopied ? "Скопировано!" : "Скопировать реферальную ссылку"}</p>
                </div>
                <a className="dl-story" href={story} download={"Краткая история"}>
                    <div className="refer">
                        <p>Краткая история науки, философии и технологий</p>
                    </div>
                </a>
                <Footer/>
            </div>
        </>
    );
}

export default Account;
