import axios from "axios";
import {USER_API} from "../const/api";

const articlesApi = axios.create({
    baseURL: USER_API,
});

export const getArticles = async () => {
    return await articlesApi.get("getArticlesF").catch((e) => {
        console.log(e)
    })
};

export const getArticleByUrl = async (URL: string) => {
    return await articlesApi.get(
        `getArticlesF?URL=${URL}`
    );
};

