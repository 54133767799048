import "../../styles/componentStyles/coursesPage/course.css";
import {fixImgSrc, getCoursesPage, getPurchasedBlocks} from "../../service/api/coursesApi";
import React, {useContext, useEffect, useRef, useState} from "react";
import useCourseInfo from "../../service/hooks/useCourseInfo"; //custom hook
import CoursePlayer from "./CoursePlayer";
import AuthContext from "../../service/context/AuthProvider"; //if auth token
import BuyCourse from "./BuyCourse";
import {Link} from "react-router-dom";
import ChatLink from "../../etc/ChatLink";
import ReviewsCarousel from "../../widgets/reviews/ReviewsCarousel";
import useSetMeta from "../../service/hooks/useSetMeta";
import ChatScreen from "./components/ChatScreen";
import {buyTinkoff} from "../../service/api/buyApi";
import FormRegister from "../../stories/FormRegister";

function ByDigit() {
	const ref = useRef<HTMLDivElement>(null)
	const { auth }: any = useContext(AuthContext);

	useSetMeta({
		title: "Онлайн-курс  “Картина цифрой”",
		description: "Погрузитесь в мир истории искусства и современного его состояния!"
	})

	const buyCourse = async () => {
		if (!localStorage.getItem("token")) {
			if (ref?.current) {
				ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
			}
		} else {
			const response = await buyTinkoff(
				localStorage.getItem("token"),
				COURSEURL
			);
			if (response?.data) {
				if (response.data) {
					window.location.replace(response.data);
				}
			}
		}
	};

	// console.log(showPlayer);
	const COURSEURL = "picture-by-digit"; //* CHANGE THIS WHEN COPY PASTING
	const LATIN = [
		"I",
		"II",
		"III",
		"IV",
		"V",
		"VI",
		"VII",
		"VIII",
		"IX",
		"X",
		"XI",
		"XII",
		"XIII",
		"XIV",
		"XV",
	];
	const [courses, setCourses] = useState<ICoursesPage | []>([]);
	const [course, setCourse] = useState<any>({});
	interface ICoursesPage {
		courseId: number;
		courseName: string;
		courseUrlid: string;
		currentPrice: any;
		description: string;
		oldPrice: any;
		srcPicturePage: string;
	}
	useEffect(() => {
		const fetchCourses = async () => {
			const responseCourses = await getCoursesPage();
			// console.log(responseCourses);
			if (responseCourses.status === 200) {
				setCourses(responseCourses.data);
				setCourse(
					await responseCourses.data.filter((course: ICoursesPage) => {
						return course.courseUrlid === COURSEURL;
					})[0]
				);
			}
		};
		fetchCourses();
	}, []);

	// console.log(course);

	//* getting button names and info (not content)
	const [courseInfo] = useCourseInfo(COURSEURL);
	// console.log(courseInfo);

	//*purchased button

	const [purchasedBlocks, setPurchasedBlocks] = useState<any>(null);

	useEffect(() => {
		const fetchPurchasedBlocks = async () => {
			if (localStorage.getItem("token")) {
				//!only if autherized
				const response = await getPurchasedBlocks(
					localStorage.getItem("token"),
					COURSEURL
				);
				if (response.data.error === "Курс не был куплен") {
					setPurchasedBlocks(null);
					return;
				}
				if (response.data) {
					setPurchasedBlocks(response.data);
					// console.log(response.data);
				}
			} else return;
		};
		fetchPurchasedBlocks();
	}, []);

	let showPlayer = false;
	if (auth && localStorage.getItem("token") && purchasedBlocks) {
		showPlayer = true;
	}

	function CourseLanding() {
		return (
			<>
				<div className="course-description">
					<p className="descr-string">Описание курса</p>
					<p className="descr-text-main-1">
						История искусства как история изобретений, благодаря которым и
						создавались настоящие шедевры!
					</p>
					<div
						style={{ justifyContent: "center", marginBottom: "4rem" }} //! тут инлайн стили
						className="descr-mid-container"
					>
						<div className="descr-how-many">
							<h2 style={{ textAlign: "center" }}>7</h2>
							<p>практических лекций</p>
						</div>
						{/* <div className="descr-who-container">
								<div className="descr-who">
									<img src="../faces/nikita_zverev.png" alt="" />
									<div>
										<p className="descr-who-reads">курс читает</p>
										<h2 className="descr-who-name">НИКИТА ЗВЕРЕВ</h2>
										<p className="descr-who-job">социолог</p>
									</div>
								</div>
							</div> */}
					</div>
					<p className="descr-text-main-2">
						В центре внимания: Как и где в сфере искусства применяются
						цифровые технологии и искусственный интеллект? Как устроен рынок
						традиционного, современного и цифрового искусства?
					</p>

					<div className="course-contains-container">
						<p className="what-course-contains-text">курс состоит из:</p>
						<div className="course-contains-grid">
							<div className="course-contains-item">
								<img src="../../digit/20.png" alt="" />
								<p>видео</p>
							</div>
							<div className="course-contains-item">
								<img className="a-text" src="../../digit/Atext.png" alt="" />
								<p>тексты,</p>
								<p className="thin">мануалы и др.</p>
							</div>
							<div className="course-contains-item">
								<img className="a-text" src="../../digit/100.png" alt="" />
								<p>минут</p>
								<p className="thin">материала</p>
							</div>
							<div className="course-contains-item">
								<img className="a-text" src="../../digit/test.png" alt="" />
								<p>вопросы</p>
								<p className="thin">к каждому блоку</p>
							</div>
						</div>
					</div>
					<p className="descr-what-you-learn-p">Что вы узнаете на курсе?</p>
					<div className="descr-what-you-learn-container">
						<div className="descr-what-you-learn-text">
							<p>
								какие технологии больше всего повлияли на историю искусства?
							</p>
						</div>
						<div className="descr-what-you-learn-text">
							<p>как цифровизация изменила традиционное искусство</p>
						</div>
						<div className="descr-what-you-learn-text">
							<p>как зарабатывают миллионы на NFT</p>
						</div>
						<div className="descr-what-you-learn-text">
							<p>история развития криптоарта и оценка рыночных тенденций</p>
						</div>
						<div/>
						<div className="descr-what-you-learn-text">
							<p>
								какие программы используют художниками и как научиться ими
								пользоваться?
							</p>
						</div>
					</div>
				</div>

				<ChatScreen type={"art"}/>

				<div className="course-program">
					<h2>Программа курса</h2>
					<div className="course-program-blocks">
						{courseInfo.p ? (
							courseInfo.p.b.map((element: any, index: any) => {
								console.log(element);
								let date = "";
								if (element.isShowAsProgrammCourse == "0") return;
								if (element.blockReleaseDate) {
									date = element.blockReleaseDate;
									date.replace("T", " ");
								}
								return (
									<div key={element["blockId"]}>
										<h3 className="block-name">
											{LATIN[index]}&nbsp;&nbsp;&nbsp;
											{element["blockName"]}&nbsp;&nbsp;
											<span className="course-block-date">
													{date &&
														date
															.replace("T", " ")
															.slice(0, -3)
															.replace("-", "/")
															.replace("-", "/")}
												</span>
										</h3>
										<div className="subblocks">
											{element?.i &&
												element.i.map((elementInner: any) => {
													return (
														<p
															key={
																elementInner &&
																elementInner["blockInfoBlockInfoId"]
															}
														>
															—{" "}
															{elementInner &&
																elementInner["blockInfoBlockInfoName"]}
														</p>
													);
												})}
										</div>
									</div>
								);
							})
						) : (
							<div>Загрузка...</div>
						)}
					</div>
				</div>
				<div className="buy-course-end">
					<BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
				</div>

				<ReviewsCarousel maxCount={5} folder={"digit"}
                                 reviews={[
									 "../../reviews/digit/1.png",
									 "../../reviews/digit/2.png",
									 "../../reviews/digit/3.png",
									 "../../reviews/digit/4.png",
									 "../../reviews/digit/5.png",
								 ]}
				/>
			</>
		)
	}

	return (
		<>
			{/* <Mrgn /> */}
			<div className="course-nav">
				<Link to="/">
					<img width="150px" height="85px" src="../../logo.png" alt="" />
				</Link>
			</div>
			{course.courseId ? (
				<div className="course-container">
					<div
						className="course-hero"
						style={{
							backgroundImage: `url(${fixImgSrc(
								"files/course-pictures/picture-by-digit-2.png"
							)})`,
						}}
					>
						<div className="course-hero-info">
							<p className="online-course-text">Онлайн курс</p>

							<h1>{course.courseName.toUpperCase()}</h1>
							<BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
						</div>
					</div>
					{
						!localStorage.getItem("token") &&
						<div style={{marginTop: 32}} ref={ref}>
							<div  className="p-2 text-center my-4 md:max-w-[1000px] m-auto">
								Для приобритения курса зарегестрируйтесь на сайте
							</div>
							<FormRegister discount={courseInfo?.p?.courseOldPrice}
										  courseOldPrice={courseInfo?.p?.courseOldPrice}
										  courseCurrentPrice={courseInfo?.p?.courseCurrentPrice}
										  buyFunc={buyCourse}/>
						</div>
					}
					{showPlayer && (
						<>
							<CoursePlayer
								purchasedBlocks={purchasedBlocks}
								courseURLId={COURSEURL}
							/>
							<ChatLink
								chatName="Чат “Искусство на (non)human””"
								chatLink="https://t.me/+kTduSj0iEPg1NjAy"
							/>
						</>
					)}

					{!showPlayer && <CourseLanding/>}
				</div>
			) : (
				<div></div>
			)}
		</>
	);
}

export default ByDigit;
