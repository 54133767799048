import axios from "axios";
import {API, USER_API} from "../const/api";

const longreadsApi = axios.create({
    baseURL: USER_API,
});

export type TLongread = {
    longreadId: number;
    title: string;
    description: string;
    htmltitle: string;
    htmldescription: string;
    url: string;
    pathToImage: string;
    pathToMarkDown: string;
    createdOn: string;
};

export const getNewLongreads = async () => {
    return await longreadsApi.get("getLongreads");
};

export const getNewLongread = async (URL: string) => {
    return await longreadsApi.get<TLongread>(
        `getLongreads?longreadURL=${URL}`
    );
};

export const getLongreadMarkdown = async (URL: string) => {
    return await axios.get(`${API}/${URL}`);
};

export type TLongreadRecommendation = {
    longreadId: number;
    longreadUrl: string;
    recommendationId: number;
    contentType: string;
    contentTitle: string;
    contentDescription: string;
    contentPicture: string;
    contentUrl: string;
};

export const getLongreadRecommendations = async (URL: string, type:number) => {
    return await longreadsApi.get<TLongreadRecommendation[]>(
        `getRecommendationsF?longreadURL=${URL}&type=${type}`
    );
};
