import Carousel from "nuka-carousel";

interface ICoursesPage {
	courseId: number;
	briefDescription: string;
	courseName: string;
	courseUrlid: string;
	currentPrice: any;
	description: string;
	oldPrice: any;
	srcPicturePage: string;
	releaseDate: any;
	srcPictureAdditional1: string;
}

const AboutCarousel = () => {
	return (
		<div
			style={{ marginTop: "-1em" }}
			className="carousel-container"
		>
			<Carousel
				pauseOnHover={true}
				wrapAround={true}
				autoplay={true}
				autoplayInterval={10000}
				animation="fade"
				defaultControlsConfig={{
					nextButtonStyle: {
						transform: "scale(1.7)",
						translate: "-10px",
						paddingTop: "1rem",
						paddingBottom: "1rem",
					},
					prevButtonStyle: {
						transform: "scale(1.7)",
						translate: "10px",
						paddingTop: "1rem",
						paddingBottom: "1rem",
					},
					nextButtonText: "❯",
					prevButtonText: "❮",
					pagingDotsStyle: {
						fill: "white",
						transform: "scale(2)",
						padding: "0.5rem",
					},
				}}
			>
				{/* тут сделать вместо инлайна все в css. Мап добавить легко. работают дивы 100%*/}
				<div
					className="carousel-img"
					style={{
						backgroundImage: `url(../about/2.png)`,
					}}
				></div>
				<div
					className="carousel-img"
					style={{
						backgroundImage: `url(../about/3.png)`,
					}}
				></div>
				<div
					className="carousel-img"
					style={{
						backgroundImage: `url(../about/4.jpg)`,
					}}
				></div>
			</Carousel>
		</div>
	);
};

export default AboutCarousel;
