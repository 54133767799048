import React from "react";
import "../../styles/componentStyles/buyCourseModal.css";
import ReactDom from "react-dom";
import {CourseInfo} from "../../service/hooks/useCourseInfoTyped";
import {IoClose} from "react-icons/io5";
import {buyTinkoff} from "../../service/api/buyApi";

import {useNavigate} from "react-router-dom";

interface IProps {
    courseInfo: CourseInfo;
    allBlocksBought: boolean;
    purchasedBlocks: string[];
    isModalVisible: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

function BuyCourseOfflineModal({
                                   courseInfo,
                                   allBlocksBought,
                                   purchasedBlocks,
                                   isModalVisible,
                                   setIsModalVisible,
                               }: IProps) {
    const navigate = useNavigate();
    //* old buycourse for testing
    // const buyCourse = async () => {
    // 	if (!localStorage.getItem("token")) {
    // 		navigate("/account");
    // 	} else {
    // 		let SecretKey: any = prompt("Введите");

    // 		console.log("course has been bought! i swera.");
    // 		postBuyCourse(
    // 			localStorage.getItem("token"),
    // 			courseInfo.courseURLId,
    // 			SecretKey
    // 		);
    // 	}
    // };

    const buyCourse = async (blockUrlid?: string) => {
        if (!localStorage.getItem("token")) {
            navigate("/account");
        } else {
            // let SecretKey: any = prompt("Введите");
            // console.log("course has been bought! i swera.");
            // postBuyCourse(localStorage.getItem("token"), COURSEURL, SecretKey);
            const response = await buyTinkoff(
                localStorage.getItem("token"),
                courseInfo.courseURLId,
                blockUrlid
            );
            if (response?.data) {
                console.log(response.data);
                if (response.data) {
                    console.log(response.data);
                    // window.open(response.data, "_blank");
                    window.location.replace(response.data);
                }
            }
        }
    };

    const formatDate = (date: string) => date
        .replace("T", " ")
        .replace("-", "/")
        .replace("-", "/")
        .slice(0, -3);

    const blocksList = courseInfo.p.b.map((block) => {
        if (purchasedBlocks.includes(block.blockURLId)) {
            return (
                <div className="buy-block-list" key={block.blockURLId}>
                    <div className="name-and-date">
                        <p>{block.blockName}</p>

                        {block.blockReleaseDate && (
                            <p className="date">{formatDate(block.blockReleaseDate)}</p>
                        )}
                    </div>
                    <div className="bought-block-btn">куплено</div>
                </div>
            );
        } else {
            if (
                !block.blockReleaseDate ||
                Date.parse(block.blockReleaseDate) >= Date.now()
            ) {
                return (
                    <div className="buy-block-list" key={block.blockURLId}>
                        <div className="name-and-date">
                            <p>{block.blockName}</p>
                            {block.blockReleaseDate && (
                                <p className="date">{formatDate(block.blockReleaseDate)}</p>
                            )}
                        </div>
                        <div
                            onClick={() =>
                                buyCourse(
                                    // localStorage.getItem("token"),
                                    // courseInfo.courseURLId,
                                    block.blockURLId
                                )
                            }
                            className="buy-block-btn"
                        >
                            {block.blockCurrentPrice} р.
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="buy-block-list" key={block.blockURLId}>
                        <div className="name-and-date-late ">
                            <p>{block.blockName}</p>
                            {block.blockReleaseDate && (
                                <p className="date">{formatDate(block.blockReleaseDate)}</p>
                            )}
                        </div>
                        <div className="late-block-btn">мероприятие прошло</div>
                    </div>
                );
            }
        }
    });

    return ReactDom.createPortal(
        <>
            <div
                className={
                    isModalVisible
                        ? "buy-course-modal-container"
                        : "buy-course-modal-container hidden"
                }
            >
                <div className="buy-course-modal-inner-container">
                    <div
                        onClick={() => setIsModalVisible(false)}
                        className="modal-close-btn"
                    >
                        {<IoClose/>}
                    </div>
                    <h2>{courseInfo.courseName}</h2>
                    <p className="buy-full-course-text">
                        Приобретите абонемент на весь оффлайн курс:
                    </p>
                    <div onClick={() => buyCourse()} className="buy-full-course-btn">
                        {courseInfo.p.courseCurrentPrice} р.
                    </div>
                    <p className="buy-block-text">
                        Или купите доступ к отдельным лекциям:
                    </p>

                    <div className="buy-block-list-container">{blocksList}</div>
                </div>
            </div>
        </>,
        document.getElementById("portal")!
    );
}

// export default BuyCourseOfflineModal;
export const BuyCourseModalMemo = React.memo(
    BuyCourseOfflineModal,
    (props, nextProps) => {
        return false;
    }
);
