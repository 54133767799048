import React from 'react';
import "../../../styles/componentStyles/subscribe/buySubscribe.css";

const CardSubscribe = (props: any) => {
    const {el, subscribe, setSubscribe} = props

    const setActive = () => {
        if (el?.subscribeId === subscribe) setSubscribe(null)
        else setSubscribe(el)
    }

    return (
        <div onClick={setActive} style={{
            textAlign: "center",
            marginBottom: 16,
            transform: el?.subscribeId === subscribe?.subscribeId ? "scale(1.2)" : undefined
        }}>
            <div style={{marginBottom: 8, fontSize: 20, fontWeight: 600}}>{el?.subscribeName}</div>
            <div className="button-buy-subscribe">
                <p
                    style={{
                        margin: 0,
                        fontSize: "1.5rem",
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                    }}
                >
                    {el?.oldPrice && (
                        <p
                            style={{
                                display: "inline",
                                margin: "0",
                                marginTop: "0rem",
                                fontSize: "1rem",
                                color: "gray",
                                textDecoration: "line-through",
                                marginRight: "1rem",
                                marginLeft: "-3rem",
                            }}
                        >
                            {el?.oldPrice} р.
                        </p>
                    )}
                    <p style={{margin: 0, marginTop: "-0.2rem"}}>
                        {el?.currentPrice} р.
                    </p>
                </p>
                <span>Приобрести</span>

                <div className="flag-container-subscribe">
                    <img src="../../flag.png" alt=""/>
                    <p className="discount">
                        -{el?.discount}%
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CardSubscribe;
