import Mrgn from "../../layout/Mrgn";
import "../../styles/componentStyles/login.css";
import {useContext, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {SubmitHandler, useForm,} from "react-hook-form";
import {ILoginUser} from "../../service/types/interfaces";
import {loginUser} from "../../service/api/authApi";
import AuthContext from "../../service/context/AuthProvider";
import {NEED_RETURN_TO_TEST_KEY} from "../test/questions";
import {COURSE_LINK_LS_KEY} from "./const";
import {RESET_PASSWORD_PATH} from "../../service/const/paths";

const LoginPage = () => {
	const navigate = useNavigate();

	const [emailError, setEmailError] = useState("");

	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm();

	const { setAuth }: any = useContext(AuthContext); // using context

	const doLogin = async (userCreds: ILoginUser) => {
		const data = await loginUser(userCreds);

		if (data.error) {
			setEmailError(data.error);
		} else {
			localStorage.setItem(`token`, `${data.token}`);
			localStorage.setItem(`refreshToken`, `${data.refreshToken}`);
			setAuth({
				token: data.token,
				refreshToken: data.refreshToken,
			});

			if (!!localStorage.getItem(COURSE_LINK_LS_KEY)) {
				let link = localStorage.getItem(COURSE_LINK_LS_KEY)
				localStorage.removeItem(COURSE_LINK_LS_KEY)
				navigate(`${link}`)
			} else if (!!localStorage.getItem(NEED_RETURN_TO_TEST_KEY)) {
				localStorage.removeItem(NEED_RETURN_TO_TEST_KEY)
				navigate("/test");
			} else {
				navigate("/");
			}
		}
	};

	const onSubmit: SubmitHandler<any> = (data) =>
		doLogin({ ...data, rememberMe: true });

	return (
		<div>
			<Mrgn />
			<div className="form-container form-login-bg">
				<h2>Вход</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="input-group">
						<div className="label-group">
							<label htmlFor="email-field">E-mail</label>
							<div className="required-error"></div>
						</div>

						<input
							id="email-field"
							{...register("email", {
								required: true,
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: "С почтовым адресом что-то не так",
								},
							})}
						/>

						<div className="input-error">
							{/* pattern error v */}
							{/* {errors?.email?.message && (
								<p>{errors.email?.message.toString()}</p>
							)} */}
						</div>
					</div>
					<div className="input-group">
						<div className="label-group">
							<label htmlFor="pass-field">Пароль</label>
							<div className="required-error"></div>
						</div>

						<input
							id="pass-field"
							type="password"
							{...register("password", {
								required: true,
								minLength: 6,
								maxLength: 100,
							})}
						/>
						<div className="input-error">
							{errors?.password?.type == "maxLength" && (
								<p>Слишком длинный пароль, максимум 100 символов ( •_•)</p>
							)}
							{emailError && <p>{`${emailError}`}</p>}
						</div>
					</div>

					<button className="button">
						<input type="submit" />
						<p>Войти</p>
					</button>
					{!!localStorage.getItem(NEED_RETURN_TO_TEST_KEY) && !localStorage.getItem(COURSE_LINK_LS_KEY) &&
						<p style={{textAlign: "center", maxWidth: "350px", marginTop: 20}}>После входа Вы будете перенаправлены на страницу теста</p>
					}
					{!!localStorage.getItem(COURSE_LINK_LS_KEY) &&
						<p style={{textAlign: "center", maxWidth: "350px", marginTop: 20}}>После входа Вы будете перенаправлены на страницу курса</p>
					}
					<p className="or-do-smth-else" style={{marginTop: 30, textDecoration: "underline"}}>
						Или <Link to="/register">зарегистрироваться</Link>
					</p>
					<Link className="forget-pass" to={RESET_PASSWORD_PATH} style={{ textDecoration: "underline"}}>
						забыли пароль?
					</Link>
				</form>
			</div>
		</div>
	);
};

export default LoginPage;
