import "../../styles/componentStyles/coursesPage/course.css";
import {fixImgSrc, getCoursesPage, getPurchasedBlocks} from "../../service/api/coursesApi";
import React, {useContext, useEffect, useRef, useState} from "react";
import useCourseInfo from "../../service/hooks/useCourseInfo"; //custom hook
import CoursePlayer from "./CoursePlayer";
import AuthContext from "../../service/context/AuthProvider"; //if auth token
import {Link} from "react-router-dom";
import BuyCourse from "./BuyCourse";
import ChatLink from "../../etc/ChatLink";
import ReviewsCarousel from "../../widgets/reviews/ReviewsCarousel";
import useSetMeta from "../../service/hooks/useSetMeta";
import ChatScreen from "./components/ChatScreen";
import {buyTinkoff} from "../../service/api/buyApi";
import FormRegister from "../../stories/FormRegister";

function HowSocietyWorks() {
    const ref = useRef<HTMLDivElement>(null)
    const {auth}: any = useContext(AuthContext);

    useSetMeta({
        title: "Онлайн-курс “Как устроено общество?”",
        description: "Освойте необходимый инструментарий социологической науки а разберитесь с тем, как устроено современное общество!"
    })

    const buyCourse = async () => {
        if (!localStorage.getItem("token")) {
            if (ref?.current) {
                ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
            }
        } else {
            const response = await buyTinkoff(
                localStorage.getItem("token"),
                COURSEURL
            );
            if (response?.data) {
                if (response.data) {
                    window.location.replace(response.data);
                }
            }
        }
    };

    // console.log(showPlayer);
    const COURSEURL = "how-is-society-organized"; //* CHANGE THIS WHEN COPY PASTING
    const LATIN = [
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
        "X",
        "XI",
        "XII",
        "XIII",
        "XIV",
        "XV",
    ];
    const [courses, setCourses] = useState<ICoursesPage | []>([]);
    const [course, setCourse] = useState<any>({});

    interface ICoursesPage {
        courseId: number;
        courseName: string;
        courseUrlid: string;
        currentPrice: any;
        description: string;
        oldPrice: any;
        srcPicturePage: string;
    }

    useEffect(() => {
        const fetchCourses = async () => {
            const responseCourses = await getCoursesPage();
            // console.log(responseCourses);
            if (responseCourses.status === 200) {
                setCourses(responseCourses.data);
                setCourse(
                    await responseCourses.data.filter((course: ICoursesPage) => {
                        return course.courseUrlid === COURSEURL;
                    })[0]
                );
            }
        };
        fetchCourses();
    }, []);

    // console.log(course);

    //* getting button names and info (not content)
    const [courseInfo] = useCourseInfo(COURSEURL);
    // console.log(courseInfo);

    //*purchased button

    const [purchasedBlocks, setPurchasedBlocks] = useState<any>(null);

    useEffect(() => {
        const fetchPurchasedBlocks = async () => {
            if (localStorage.getItem("token")) {
                //!only if autherized
                const response = await getPurchasedBlocks(
                    localStorage.getItem("token"),
                    COURSEURL
                );
                if (response.data.error === "Курс не был куплен") {
                    setPurchasedBlocks(null);
                    return;
                }
                if (response.data) {
                    setPurchasedBlocks(response.data);
                    // console.log(response.data);
                }
            } else return;
        };
        fetchPurchasedBlocks();
    }, []);

    let showPlayer = false;
    if (auth && localStorage.getItem("token") && purchasedBlocks) {
        showPlayer = true;
    }

    function CourseLanding() {
        return (
            <>
                <div className="course-description">
                    <p className="descr-string">Описание курса</p>
                    <p className="descr-text-main-1">
                        Социальные и естественные науки на протяжении нескольких сотен лет
                        создают модели нашего общества и изобретают новые методы. Данный
                        курс последовательно введет вас в основные идеи наук, изучающих
                        общество и вооружит вас понимаем всех окружающих социальных
                        явлений.
                    </p>
                    <div className="descr-mid-container">
                        {/* <div className="descr-how-many">
								<h2>6</h2>
								<p>практических лекций</p>
							</div> */}
                        {/* <div className="descr-who-container">
								<div className="descr-who">
									<img src="../faces/alena_kuzmina.jpg" alt="" />
									<div>
										<p className="descr-who-reads">Специальный гость проекта</p>
										<h2 className="descr-who-name">АЛЕНА КУЗЬМИНА</h2>
										<p className="descr-who-job">
											эксперт по астрологии, телеведущая
										</p>
									</div>
								</div>
							</div> */}
                    </div>

                    <p className="descr-what-you-learn-p">Что вы узнаете на курсе?</p>
                    <div className="descr-what-you-learn-container">
                        <div className="descr-what-you-learn-text">
                            <p>
                                Как устроено современное общество и какими методами мы можем
                                его изучать
                            </p>
                        </div>
                        <div className="descr-what-you-learn-text">
                            <p>
                                Какой была история социальных идей и благодаря чему она
                                двигалась
                            </p>
                        </div>
                        <div className="descr-what-you-learn-text">
                            <p>
                                Получите представление о социальных науках, как они работают и
                                каково их современное состояние
                            </p>
                        </div>
                        <div className="descr-what-you-learn-text">
                            <p>
                                Как общество осмысляли великие философы разных эпох
                            </p>
                        </div>
                        <div/>
                        <div className="descr-what-you-learn-text">
                            <p>
                                В чем заключается сущность общества, как и кто им управляет
                            </p>
                        </div>
                    </div>
                    <div className="course-contains-container">
                        <p className="what-course-contains-text">курс состоит из:</p>


                        <div className="course-contains-grid-2items">
                            <div className="course-contains-item">
                                <h2>6</h2>
                                <p>больших увлекательных тематических блока</p>
                            </div>

                            <div className="course-contains-item">
                                <img className="a-text" src="../../digit/Atext.png" alt="" />
                                <p>много</p>
                                <p className="thin">
                                    систематизированного авторского текстового материала
                                </p>
                            </div>
                        </div>

                        <div className="descr-mid-container">
                            <div className="descr-how-many">

                            </div>
                            <div className="descr-who-container">
                                <div className="descr-who">
                                    <img src="../faces/nikita.jpg" alt=""/>
                                    <div>
                                        <p className="descr-who-reads">КУРС ЧИТАЕТ</p>
                                        <h2 className="descr-who-name">НИКИТА ЗВЕРЕВ</h2>
                                        <p className="descr-who-job">
                                            философ, психолог, основатель академии “Нус”
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="present">
                    <div>
                        <h2>подарок</h2>
                        <p>запись онлайн-вебинара</p>
                        <p>
                            на тему тайных обществ и эзотерических основаниях мировых элит{" "}
                        </p>
                    </div>
                    <div>
                        <img src="../gift_icon.png" alt=""/>
                    </div>
                </div>

                <ChatScreen type={"socio"}/>

                <div className="course-program">
                    <h2>Программа курса</h2>
                    <div className="course-program-blocks">
                        {courseInfo.p ? (
                            courseInfo.p.b.map((element: any, index: any) => {
                                return (
                                    <div key={element["blockId"]}>
                                        <h3 className="block-name">
                                            {LATIN[index]}&nbsp;&nbsp;&nbsp;
                                            {element["blockName"]}
                                        </h3>
                                        <div className="subblocks">
                                            {element.i.map((elementInner: any) => {
                                                return (
                                                    <p key={elementInner["blockInfoBlockInfoId"]}>
                                                        — {elementInner["blockInfoBlockInfoName"]}
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div>Загрузка...</div>
                        )}
                    </div>
                </div>
                <div className="buy-course-end">
                    <BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
                </div>

                <ReviewsCarousel folder={"howSocietyWorks"} maxCount={3}
                                 reviews={[
                                     "../../reviews/howSocietyWorks/1.png",
                                     "../../reviews/howSocietyWorks/2.png",
                                     "../../reviews/howSocietyWorks/3.png",
                                 ]}
                />
            </>
        )
    }

    return (
        <>
            {/* <Mrgn /> */}
            <div className="course-nav">
                <Link to="/">
                    <img width="150px" height="85px" src="../../logo.png" alt=""/>
                </Link>
            </div>
            {course.courseId ? (
                <div className="course-container">
                    <div
                        className="course-hero"
                        style={{
                            backgroundImage: `url(${fixImgSrc(
                                "files/course-pictures/how-is-society-organized.jpg"
                            )})`,
                        }}
                    >
                        <div className="course-hero-info">
                            <p className="online-course-text">Онлайн курс</p>

                            <h1>{course.courseName.toUpperCase()}</h1>
                            <BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
                        </div>
                    </div>
                    {
                        !localStorage.getItem("token") &&
                        <div style={{marginTop: 32}} ref={ref}>
                            <div className="p-2 text-center my-4 md:max-w-[1000px] m-auto">
                                Для приобритения курса зарегестрируйтесь на сайте
                            </div>
                            <FormRegister discount={courseInfo?.p?.courseOldPrice}
                                          courseOldPrice={courseInfo?.p?.courseOldPrice}
                                          courseCurrentPrice={courseInfo?.p?.courseCurrentPrice}
                                          buyFunc={buyCourse}/>
                        </div>
                    }
                    {showPlayer && (
                        <>
                            <CoursePlayer
                                purchasedBlocks={purchasedBlocks}
                                courseURLId={COURSEURL}
                            />
                            <ChatLink
                                chatName="Чат “Социологический (non)human”"
                                chatLink="https://t.me/+9rk2iMgUH2tjNDAy"
                            />
                        </>
                    )}

                    {!showPlayer && <CourseLanding/>}

                </div>
            ) : (
                <div></div>
            )}
        </>
    );
}

export default HowSocietyWorks;
