import {Navigate, useLocation} from "react-router-dom";
import AuthContext from "../service/context/AuthProvider";
import {useContext} from "react";

function RequireAuth({ children }: any) {
	const location = useLocation();
	// const [user, setUser] = useGetUser();
	const localAuth = localStorage.getItem("token");
	const { auth }: any = useContext(AuthContext); // using context
	console.log(auth);
	if (auth.token && auth.token != undefined) {
		return children;
	} else if (localAuth) {
		return children;
	} else {
		return <Navigate to="/login" />;
	}
}

export default RequireAuth;
