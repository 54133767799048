import {useEffect, useState} from "react";
import {TLongread} from "../../service/api/longreadsApi";
import Mrgn from "../../layout/Mrgn";
import HTwo from "../../components/headers/HTwo";
import LongreadCard from "../../stories/longreads/LongreadCard";
import {API} from "../../service/const/api";
import {getNews} from "../../service/api/newsApi";

export function ContainerNews() {
    const [data, setData] = useState<TLongread[] | null>(null);

    useEffect(() => {
        getNews().then((res) => setData(res?.data))
    }, []);

    return (
        <div>
            <Mrgn/>
            <HTwo>Новости</HTwo>
            <div className="longreads-container">
                {
                    (Array.isArray(data) && data?.map((longread: TLongread) => (
                        <LongreadCard
                            img={`${API}/${longread.pathToImage}`}
                            name={longread.title}
                            to={longread.url}
                            descr={longread.description}
                            key={`longreads/${longread.url}`}
                        />
                    )))
                }
            </div>
        </div>
    );
}
