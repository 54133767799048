import React, {useState} from "react";
import Mrgn from "../../layout/Mrgn";
import "../../styles/unsubscribe/unsubscribe.css";
import {useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import { API } from "../../service/const/api";

function Unsubscribe() {
	const [noUnsub, setNoUnsub] = useState(false);
	const [unsubbed, setUnsubbed] = useState(false);
	//
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const id = searchParams.get("email");

	// post

	const unsubscribePost = async (email: any) => {
		try {
			const response = await axios.put(
				`${API}/api/User/Unsubscribe?email=${email}`
			);
			console.log(response);
		} catch (err) {
			console.log("произошла ошибка");
		}
	};

	// click functions

	const noClicked = () => {
		setNoUnsub(true);
		setTimeout(() => {
			navigate("/");
		}, 2000);
	};

	const yesClicked = () => {
		unsubscribePost(id);
		setUnsubbed(true);
		setTimeout(() => {
			navigate("/");
		}, 2000);
	};

	if (noUnsub) {
		return (
			<>
				<Mrgn />
				<main className="unsub-main">
					<h4>:)</h4>
					<p>Спасибо, сейчас мы вернем Вас на главную</p>
				</main>
			</>
		);
	}

	if (unsubbed) {
		return (
			<>
				<Mrgn />
				<main className="unsub-main">
					<h4>:(</h4>
					<p>Мы отписали вас, сейчас вернем Вас на главную</p>
				</main>
			</>
		);
	}

	//* default return
	return (
		<>
			<Mrgn />
			<main className="unsub-main">
				<h4>:(</h4>
				<p>Вы уверены, что хотите отписаться от нашей рассылки?</p>
				<div className="y-no">
					<div onClick={yesClicked} className="option yes">
						<h5>Да,</h5>
						<p>отпишите меня</p>
					</div>
					<div onClick={noClicked} className="option no">
						<h5>Нет,</h5>
						<p>я передумал</p>
					</div>
				</div>
			</main>
		</>
	);
}

export default Unsubscribe;
