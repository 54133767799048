import React from 'react';
import mobile from  "./img/mobile.png"
import psycho from "./img/psycho.png"
import socio from "./img/socio.png"
import fil from "./img/fil.png"
import art from "./img/art.png"
import "./style.css"

interface IProps {
    type: string,
    title: any
}
const ChatScreen = (props: any) => {
    const type = props.type
    const types = {
        psycho: {
            img: psycho,
            target: "психологическому чату"
        },
        socio: {
            img: socio,
            target: "социологическому чату"
        },
        fil: {
            target: "философскому чату",
            img: fil,
        },
        art: {
            target: "чату по теме искусства",
            img: art
        }
    }
    // @ts-ignore
    const target = types[type]?.target ?? "бизнес-чату"
    // @ts-ignore
    const img = types[type]?.img ?? mobile
    return (
        <div className="course-program chat-screen">
            <h2 style={{marginInline: "auto"}}>
                {props?.title ??
                    <span>В курс входит доступ к <br/> специальному {target}</span>
                }
            </h2>
            <div className={"screen-grid"}>
                <img src={img} alt={"business-chat"}/>
                <div>
                    <ul>
                        <li>Общайтесь на любимую тему с единомышленниками</li>
                        <li> Задавайте вопросы спикерам и организаторам</li>
                        <li>Получайте интересные дополнительные тематические материалы</li>
                        <li>Знакомьтесь и обретайте полезные связи</li>
                    </ul>

                    {type==="business" &&
                        <div className={"sum"}>
                            + ГОТОВЫЕ БИЗНЕС-СИСТЕМЫ, КОТОРЫЕ МОЖЕТ ИСПОЛЬЗОВАТЬ КАЖДЫЙ БЕЗ БОЛЬШИХ ВЛОЖЕНИЙ
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ChatScreen;