import {useContext, useEffect, useState} from "react";
import AuthContext from "../context/AuthProvider";
import {getUser} from "../api/authApi";

const useGetUser = () => {
	interface IUser {
		name: string;
		id: string;
		email: string;
		role: any;
	}

	type User = IUser | null;
	const [user, setUser] = useState<User>(null);
	const { auth }: any = useContext(AuthContext); // using context

	useEffect(() => {
		// console.log("first");
		const fetchUser = async () => {
			try {
				const userData = await getUser(auth.token);
				// console.log(
				// 	"🚀 ~ file: useGetUser.ts ~ line 23 ~ fetchUser ~ userData",
				// 	userData
				// );
				setUser(userData);
				// console.log("in user: console logging user: ", user);
			} catch (error) {
				// console.log(error);
				setUser(null);
			}
		};
		fetchUser();
	}, [auth]);
	// console.log("and useGetUser ends.");

	return [user];
};

export default useGetUser;
