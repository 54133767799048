import React, {useEffect, useState} from "react";
import useCourseInfoTyped from "../../service/hooks/useCourseInfoTyped";
import {getPurchasedBlocksTyped} from "../../service/api/coursesApi";
import {BuyCourseModalMemo} from "./BuyCourseOfflineModal";
import {useLocation} from "react-router-dom";
import {COURSE_LINK_LS_KEY} from "../authPages/const";
import "../../styles/componentStyles/coursesPage/buyCourse.css";

interface iProps {
    COURSEURL: string;
    buyCourse?: any
}

function BuyCourse({COURSEURL, buyCourse}: iProps) {
    const [courseInfo] = useCourseInfoTyped(COURSEURL);
    const [allBlocksBought, setAllBlocksBought] = useState<boolean>(false);
    const [purchasedBlocks, setPurchasedBlocks] = useState<string[]>([]);
    const [isModalVisibleOffline, setIsModalVisibleOffline] = useState<boolean>(false);

    const location = useLocation()
    useEffect(() => {
        if (location.pathname?.includes("courses")) localStorage.setItem(COURSE_LINK_LS_KEY, location.pathname)
    }, [location.pathname]);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            const fetchBoughtBlocks = async () => {
                const response = await getPurchasedBlocksTyped(
                    localStorage.getItem("token"),
                    COURSEURL
                );
                console.log(response?.data)
                if (response?.data) {
                    let checkAllBoughtArr: string[] = [];
                    response.data.forEach((element) => {
                        if (!checkAllBoughtArr.includes(element.blockUrlid)) {
                            checkAllBoughtArr.push(element.blockUrlid);
                            setPurchasedBlocks(checkAllBoughtArr);
                        }
                        if (checkAllBoughtArr.length == courseInfo?.p.b.length) {
                            setAllBlocksBought(true);
                            setPurchasedBlocks(checkAllBoughtArr);
                        }
                    });
                }
            };
            fetchBoughtBlocks();
        }
    }, [courseInfo]);

    if (
        courseInfo &&
        courseInfo.courseFormat === "Offline" &&
        courseInfo.isAvailableToBuyCoursePart === "1"
    ) {
        if (!allBlocksBought) {
            return (
                <>
                    <div
                        onClick={() => setIsModalVisibleOffline(true)}
                        className="button-buy-course buy-course"
                    >
                        <p
                            style={{
                                margin: 0,
                                fontSize: "1.5rem",
                                display: "flex",
                                alignContent: "center",
                                justifyContent: "center",
                            }}
                        >
                            {courseInfo.p.courseDiscount && (
                                <p
                                    style={{
                                        display: "inline",
                                        margin: "0",
                                        marginTop: "0rem",
                                        fontSize: "1rem",
                                        color: "gray",
                                        textDecoration: "line-through",
                                        marginRight: "1rem",
                                        marginLeft: "-3rem",
                                    }}
                                >
                                    {courseInfo.p.courseOldPrice} р.
                                </p>
                            )}
                            <p style={{margin: 0, marginTop: "-0.2rem"}}>
                                {courseInfo.p.courseCurrentPrice} р.
                            </p>
                        </p>
                        <span>Приобрести</span>
                        {courseInfo.p.courseDiscount && (
                            <div className="flag-container">
                                <img src="../../flag.png" alt=""/>
                                <p className="discount">
                                    -{courseInfo.p.courseDiscount.slice(2, 4)}%
                                </p>
                            </div>
                        )}
                    </div>

                    <BuyCourseModalMemo courseInfo={courseInfo}
                                        allBlocksBought={allBlocksBought}
                                        purchasedBlocks={purchasedBlocks}
                                        isModalVisible={isModalVisibleOffline}
                                        setIsModalVisible={setIsModalVisibleOffline}
                    />
                </>
            );
        }
        if (allBlocksBought) {
            return (
                <div className="button-buy-course buy-course button-course-bought">
                    курс куплен
                </div>
            );
        }
    }

    if (courseInfo?.isActive === "0") {
        return (
            <div className="button-buy-course buy-course button-course-bought">
                Курс пока недоступен
            </div>
        );
    }

    if (allBlocksBought) {
        return (
            <div className="button-buy-course buy-course button-course-bought">
                курс куплен
            </div>
        );
    } else if (courseInfo) {
        return (
            <div style={{marginTop: 32}}>
                <div onClick={() => buyCourse()}
                     className="button-buy-course buy-course"
                >
                    <p style={{
                        margin: 0,
                        fontSize: "1.5rem",
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                    }}
                    >
                        {courseInfo.p.courseDiscount && (
                            <p
                                style={{
                                    display: "inline",
                                    margin: "0",
                                    marginTop: "0rem",
                                    fontSize: "1rem",
                                    color: "gray",
                                    textDecoration: "line-through",
                                    marginRight: "1rem",
                                    marginLeft: "-3rem",
                                }}
                            >
                                {courseInfo.p.courseOldPrice} р.
                            </p>
                        )}
                        <p style={{margin: 0, marginTop: "-0.2rem"}}>
                            {courseInfo.p.courseCurrentPrice} р.
                        </p>
                    </p>
                    <span>Приобрести</span>
                    {courseInfo.p.courseDiscount && (
                        <div className="flag-container">
                            <img src="../../flag.png" alt=""/>
                            <p className="discount">
                                -{courseInfo.p.courseDiscount.slice(2, 4)}%
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default BuyCourse;
