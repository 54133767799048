import React, {useState} from 'react';
import useLocalStorage from "./useLocalStorage";
import {ANSWERS_KEY, COUNTS_KEY, results} from "./questions";

const Question = props => {
    const {item, answers, setAnswers, index} = props

    const [getLsAnswers, setLsAnswers] = useLocalStorage(ANSWERS_KEY, {})
    const [getLsCounts, setLsCounts] = useLocalStorage(COUNTS_KEY, {...results})

    function updateAnswers (newIndex, newValue, answerCounts) {
        setAnswers({...answers, [newIndex]: newValue})
        setLsAnswers({...answers, [newIndex]: newValue})

        const newCounts = {...props.counts}
        Object.entries(answerCounts).map(([key, value])=>newCounts[key]=newCounts[key]+value)
        props.setCounts(newCounts)
        setLsCounts(newCounts)
    }

    return (
        <div>
            <b>{item.question}</b>

            <div className={"flex flex-col"} style={{gap: 10, marginTop: 15}}>
                {item.answers.map(el=>
                    <div style={{padding: "10px 15px", display: "flex", gap: 10, border: '1px solid #fff', borderRadius: 10, cursor: "pointer"}}
                         onClick={()=>updateAnswers(index, el.answer, el.counts)}
                    >
                        <input type={"radio"} checked={answers[index]===el.answer} style={{minWidth: 13}}/>
                        {el.answer}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Question;