import React from "react";

function Privacy() {
	const htmlraw = `
	<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:center;'><strong><span style="font-size:16px;color:black;">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ДЛЯ ВЕБ-САЙТА</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">1. ОПРЕДЕЛЕНИЕ ПОНЯТИЙ</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">&quot;<strong>Закон</strong>&quot; означает Федеральный закон Российской Федерации &quot;О персональных данных&quot; со всеми изменениями и дополнениями, а также иные законодательные акты Российской Федерации.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">&quot;<strong>Сайт</strong>&quot; означает информационную&nbsp;</span><span style="font-size:16px;color:black;">единицу</span><span style="font-size:16px;color:black;">&nbsp;в Интернете, ресурс из веб-страниц (документов), которые объединены общей темой и связаны между друг с другом с помощью ссылок. Он зарегистрирован на Правообладателя и обязательно привязан к конкретному домену, являющемуся его адресом. Настоящая Политика разработана для следующего сайта: http://nonhuman.ru/</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">&quot;<strong>Персональные данные</strong>&quot; означает совокупность личных данных и/или неперсонифицированной информации о Пользователе, предоставляемую им самим Правообладателю и/или автоматически собираемую Правообладателем и/или третьими лицами.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">&quot;<strong>Политика</strong>&quot; означает настоящею Политику конфиденциальности для веб-сайта (со всеми существующими дополнениями и изменениями).</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">&quot;<strong>Пользователь</strong>&quot; означает юридическое или физическое лицо, которое пользуется Сайтом и/или получило к нему доступ с какого-либо устройства.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">&quot;<strong>Пользовательское соглашение</strong>&quot; означает соглашение, заключаемое между Правообладателем и Пользователем в отношении порядка, правил и особенностей использования Сайта. Пользователь присоединяется к такому соглашению и не имеет права вносить и/или требовать внесения в него каких-либо изменений или дополнений.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">&quot;<strong>Правообладатель</strong>&quot; означает следующее лицо, на имя которого зарегистрирован Сайт:</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;'><span style="font-size:16px;color:black;">Индивидуальный предприниматель Зверев Никита Сергеевич</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;'><span style="font-size:16px;color:black;">ОГРН 322784700233364</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;'><span style="font-size:16px;color:black;">ИНН 781131547487</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;'><span style="font-size:16px;color:black;">Р\с 40802810300003590498</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;'><span style="font-size:16px;color:black;">БИК 044525974</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;'><span style="font-size:16px;color:black;">АО &laquo;ТИНЬКОФФ БАНК&raquo;</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;'><span style="font-size:16px;color:black;">Кор. счет 30101810145250000974</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;'><span style="font-size:16px;color:black;">Адрес: Москва, 127287, ул. Хуторская 2-я, д. 38А, стр 26</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">&quot;<strong>Файлы куки</strong>&quot; означает небольшие файлы, отправляемые каким-либо сайтом, и размещаемые на компьютерах, смартфонах, планшетах, часах и других мобильных устройствах Пользователя, для улучшения работы сайтов, а также качества размещенного в них контента.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;"><br>&nbsp;<br>&nbsp;2. ОТНОШЕНИЯ, НА КОТОРЫЕ РАСПРОСТРАНЯЕТСЯ ПОЛИТИКА</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Общие положения</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Данная Политика используется и применима исключительно к Персональным данным, получаемым от Пользователя в связи с использованием им Сайта. Положения данной Политики направлены на:</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(1)</span></strong><span style="font-size:16px;color:black;">&nbsp;определение видов и типов получаемых Персональных данных, направлений и целей использования (обработки) Персональных данных, а также источников получения таких Персональных данных; и</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(2)</span></strong><span style="font-size:16px;color:black;">&nbsp;определение прав Пользователя в отношении защиты конфиденциальности передаваемых им Персональных данных; и</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(3)</span></strong><span style="font-size:16px;color:black;">&nbsp;определение лиц, ответственных за обработку и хранение Персональных данных, а также третьих лиц, которым такие данные раскрываются (полностью или частично).</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Правила настоящей Политики не применяются в случае обработки третьими лицами Персональных данных, которые добровольно предоставляются Пользователем.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Посредством использования Сайта Пользователь соглашается с условиями данной Политики и дает свое согласие Правообладателю на сбор, обработку, удержание и хранение Персональных данных в порядке и на условиях, предусмотренных настоящей Политикой.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Если Пользователь не согласен с условиями Политики и/или отдельные условия Политики ему не понятны, в таком случае Пользователь обязан немедленно прекратить использование Сайта.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Права пользователя по защите персональных данных</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">В связи с предоставлением Персональных данных Пользователь автоматически получает следующие права:</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(1)</span></strong><span style="font-size:16px;color:black;">&nbsp;получать данные, касающиеся их обработки (основания и цели такой обработки, применяемые способы обработки, сведения о лицах, которые имеют доступ к ним или которым они могут быть раскрыты на основании договора или Закона).</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(2)</span></strong><span style="font-size:16px;color:black;">&nbsp;получать данные о месте нахождения и идентификационных данных лиц, совершающих обработку Персональных данных.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(3)</span></strong><span style="font-size:16px;color:black;">&nbsp;получать данные о сроках хранения Персональных данных.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(4)</span></strong><span style="font-size:16px;color:black;">&nbsp;получать данные об осуществленной или о предполагаемой трансграничной передаче Персональных данных.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(5)</span></strong><span style="font-size:16px;color:black;">&nbsp;обжаловать действия или бездействие Правообладателя в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(6)</span></strong><span style="font-size:16px;color:black;">&nbsp;получать возмещение убытков и/или компенсацию морального вреда в судебном порядке в следствие допущенных Правообладателем и/или третьими лицами нарушений прав Пользователя на охрану и защиту его Персональных данных.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(7)</span></strong><span style="font-size:16px;color:black;">&nbsp;реализовывать иные права в области защиты персональных данных, предусмотренные Законом или положениями данной Политики.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;"><br>&nbsp;<br>&nbsp;3. ПЕРЕЧЕНЬ СОБИРАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Неперсонифицированная информация о пользователях</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">В связи с использованием Сайта Правообладатель может автоматически собирать и обрабатывать следующею неперсонифицированную информацию о Пользователе:</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(1)</span></strong><span style="font-size:16px;color:black;">&nbsp;информацию о трафике, возможном количестве совершенных кликов, логи и другие данные.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(2)</span></strong><span style="font-size:16px;color:black;">&nbsp;информацию об устройстве (идентификационный номер устройства, с которого выполняется вход, операционная система, платформа, тип браузера и другая информация о браузере, IP адрес).</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Личные данные о пользователях</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Пользователь предоставляет Правообладателю о себе следующие личные данные:</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(1)</span></strong><span style="font-size:16px;color:black;">&nbsp;адрес электронной почты.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(2)</span></strong><span style="font-size:16px;color:black;">&nbsp;номер мобильного телефона.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(3)</span></strong><span style="font-size:16px;color:black;">&nbsp;данные из следующей социальной сети: Vkontakte. Правообладатель может собирать, обрабатывать и хранить ID Пользователя любой из социальных сетей, которая была использована Пользователем при регистрации и/или входе на Сайт. Если регистрация Пользователя на Сайте осуществляется с помощью одного из его аккаунтов в социальных сетях, Пользователь тем самым предоставляет Правообладателю автоматическое согласие на сбор, обработку и хранение Персональных данных, которые стали доступны Правообладателю через выбранную социальную сеть.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(4)</span></strong><span style="font-size:16px;color:black;">&nbsp;данные, которые содержатся в личном кабинете (профиле) Пользователя, все внутренние переписки Пользователя (если есть), а также иная активность личного кабинета (профиля) Пользователя.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(5)</span></strong><span style="font-size:16px;color:black;">&nbsp;данные о совершенных Пользователям заказах и/или полученных/оплаченных услугах через Сайт.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(6)</span></strong><span style="font-size:16px;color:black;">&nbsp;данные и информация, получаемая в результате объединения тех или иных Персональных данных конкретного Пользователя, а также данные и информация, получаемые данные о Пользователе, получаемые от третьих лиц (партнеров, маркетологов, исследователей).</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Пользователь является единственным ответственным лицом за полноту предоставляемых личных (персональных) данных и обязан осуществлять своевременное их изменение (обновление, проверку, корректировку) на регулярной основе.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Правообладатель исходит из того, что все предоставленные Пользователем личные (персональные) данные являются достоверными, и что Пользователь поддерживает такую информацию в актуальном состоянии.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Информация о совершаемых транзакциях</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Пользователь через Сайт может осуществлять оплату за товары или услуги, посредством введения в специальное поле информации о платежной карте и идентификационных данных собственника такой карты. Пользователь может совершать оплату следующим способом:</span></p>
<ul style="margin-bottom:0in;margin-top:0in;" type="disc">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;color:black;text-align:justify;'><span style="font-size:16px;">с помощью платежной системы Яндекс.Деньги.</span></li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;color:black;text-align:justify;'><span style="font-size:16px;">а также следующим способом: ЮMoney.</span></li>
</ul>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Сбор и обработка данных о Пользователе в данном случае осуществляется исключительно для целей проведения оплаты, недопущения мошенничества, а также соблюдения иных требований Закона.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Пользователь дает свое согласие на доступ и сбор Правообладателем и соответствующей платежной системой или банковским учреждением, через которую/которое проводится оплата, к таким Персональным данным, а также соглашается с политикой конфиденциальности соответствующей платежной системы или банковского учреждения.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Использование файлов куки</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Сайт применяет определенные Файлы куки для сохранения IP-адреса, предпочтений Пользователей или типа используемого устройства с целью <strong>(1)</strong> ведения статистики посещений и трафика сайта, и <strong>(2)</strong> персонализации выводимых на экран Пользователя данных, и <strong>(3)</strong> сохранения данных, необходимых для идентификации Пользователя, в том числе при доступе с разных устройств, и <strong>(4)</strong> показа рекламы в соответствии с интересами и предпочтениями Пользователя. Сайт может использовать как собственные Файлы куки, принадлежащие Правообладателю, так и Файлы куки третьих лиц.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Перечень используемых Файлов куки, способ их взаимодействия с устройством Пользователя, а также вид и объем получаемых Персональных данных, регулируется отдельной Политикой конфиденциальности куки-файлов, с которой можно ознакомиться по следующей ссылке: http://nonhuman.ru/hooks.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;"><br>&nbsp;<br>&nbsp;4. ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Определение целей обработки</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Сбор и обработка Персональных данных осуществляется в следующих целях:</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(1)</span></strong><span style="font-size:16px;color:black;">&nbsp;для анализа поведения Пользователя, а также выявления предпочтений Пользователя к определенному виду контента.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(2)</span></strong><span style="font-size:16px;color:black;">&nbsp;для оперативной и корректной работы Сайта, улучшения его функциональности и и контента.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(3)</span></strong><span style="font-size:16px;color:black;">&nbsp;для идентификации Пользователя.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(4)</span></strong><span style="font-size:16px;color:black;">&nbsp;для направления персонализированных рекламных и маркетинговых материалов на указанный электронный адрес и/или мобильный телефон Пользователя.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(5)</span></strong><span style="font-size:16px;color:black;">&nbsp;для соблюдения требований Закона.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(6)</span></strong><span style="font-size:16px;color:black;">&nbsp;для отслеживания заказов, совершенных Пользователем через Сайт.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(7)</span></strong><span style="font-size:16px;color:black;">&nbsp;для технической поддержки Сайта, выявления проблем в его работе и их устранение.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(8)</span></strong><span style="font-size:16px;color:black;">&nbsp;для поддержания связи с Пользователем (коммуникация).</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(9)</span></strong><span style="font-size:16px;color:black;">&nbsp;для выполнения иных обязательств Правообладателя, которые возникли перед Пользователем.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(10)</span></strong><span style="font-size:16px;color:black;">&nbsp;для любых других целей, при условии получения отдельного согласия от Пользователя.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Обработка Персональных данных осуществляется на основе принципов: <strong>(1)</strong> законности целей и способов обработки; и <strong>(2)</strong> добросовестности; и <strong>(3)</strong> соответствия целей обработки Персональных данных целям, заранее определенным и заявленным при сборе таких Персональных данных; и <strong>(4)</strong> соответствия объема и характера обрабатываемых Персональных данных заявленным целям их обработки.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Условия обработки персональных данных</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Обработка Персональных данных проводится в случаях: <strong>(1)</strong> получения согласия от Пользователя; или <strong>(2)</strong> достижения Правообладателем целей, предусмотренных международным договором или Законом; или <strong>(3)</strong> предоставления Пользователем своих Персональных данных неограниченному кругу лицу; или <strong>(4)</strong> выполнения иных обязательств Правообладателя перед Пользователем, включая, однако не ограничиваясь, предоставление определенного контента Пользователю; или <strong>(5)</strong> спасения жизни или здоровья Пользователя, когда согласие на обработку его Персональных данных не удается получить заблаговременно.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">В случае обезличивания Персональных данных, что не позволяет прямо или опосредованно определить Пользователя, последующее использование и раскрытие таких данных третьим лицам допускается и в отношении их более не применяются правила данной Политики.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Правообладатель принимает все возможные меры для защиты конфиденциальности полученных Персональных данных, за исключением случаев, когда Пользователь сделал такие данные общедоступными.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Обработка Персональных данных осуществляется с использованием средств автоматизации и без использования таких средств автоматизации.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;"><br>&nbsp;<br>&nbsp;5. ДОСТУП ТРЕТЬИХ ЛИЦ К ПЕРСОНАЛЬНЫМ ДАННЫМ</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Использование аналитических платформ</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Правообладатель использует аналитическую платформу Yandex для <strong>(1)</strong> отслеживания частоты посещаемости сайта Пользователями; и <strong>(2)</strong> отслеживания способов использования Пользователем Сайта и/или его контента; и <strong>(3)</strong> выявления типа и вида контента, который является популярными среди Пользователей; и <strong>(4)</strong> определения места нахождения Пользователя. Пользователь также дает свое согласие Правообладателю на использование им информации, полученной о Пользователе от Yandex.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Для указанных целей аналитическая платформа Yandex может собирать данные об IP адресе, геолокации, поведении Пользователя, а также его предпочтениях и интересе в отношении определенного контента.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Аналитическая платформа Yandex получает доступ к Персональным данным с тем, чтоб предоставить Правообладателю понимание того насколько эффективно работает его Сайт, какой именно контент является популярным, насколько эффективно размещение в нем той или иной рекламы, а также для целей разработки и/или улучшения существующей маркетинговой стратегии Правообладателя.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">С момента использования Сайта Пользователь соглашается с политикой конфиденциальности (Privacy Policy) Yandex, а также с автоматической установкой на устройство Пользователя соответствующих Файлов куки.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Раскрытие персональных данных третьим лицам</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Правообладатель имеет право раскрывать Персональные данные <strong>(1)</strong> своим аффилированным лицам, филиалам и представительствам, открытым как на территории Российской Федерации, так и на территории других государств; <strong>(2)</strong> правопреемникам Правообладателя, которые возникли в результате его ликвидации, реорганизации или банкротства, и которые получили исключительные права владения Сайтом; <strong>(3)</strong> поставщикам платежных услуг или банковским (финансовым) учреждениям, для проведения транзакций Пользователя через Сайт; <strong>(4)</strong> третьим лицам исключительно для целей оказания получения Пользователем определенного контента или доступа к нему; <strong>(5)</strong> третьим лицам, когда Пользователем было дано согласие на раскрытие, передачу или обработку своих Персональных данных, а также в иных случаях, прямо предусмотренных Законом или данной Политикой.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Правообладатель раскрывает Персональные данные только в том случае, если <strong>(1)</strong> уверен, что третьи лица будут соблюдать условия данной Политики и предпринимать такие же меры по защите конфиденциальности Персональных данных, которые предпринимает сам Правообладатель, и <strong>(2)</strong> согласие на такое раскрытие было предварительно выражено Пользователем и/или допускается на основании Закона.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;"><br>&nbsp;<br>&nbsp;6. РАЗМЕЩЕНИЕ РЕКЛАМЫ</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Реклама на Сайте</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Правообладатель не размещает рекламу на Сайте.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Рассылка рекламных материалов</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Пользователь соглашается с правом Правообладателя отправлять на предоставленный электронный адрес и/или мобильный телефон персонализированные рекламные и маркетинговые материалы.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Пользователь имеет право в любой момент отказаться от получения таких рекламных и маркетинговых материалов посредством выполнения следующих действий:</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><em><span style="font-size:16px;color:black;">Направить письмо с просьбой отключения от рассылки на почту:&nbsp;</span></em><em><span style="font-size:16px;color:black;">info</span></em><em><span style="font-size:16px;color:black;">@nonhuman.ru</span></em></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Обращаем внимание, что в случае отказа от получения рекламных и маркетинговых материалов, Пользователь может продолжать получать от Правообладателя какие-либо уведомления, связанные с порядком использования Сайта и/или его контента.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;"><br>&nbsp;<br>&nbsp;7. НАПРАВЛЕНИЕ ЖАЛОБ И ЗАПРОСОВ ПРАВООБЛАДАТЕЛЮ</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Требование о прекращении обработки персональных данных</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Каждый Пользователь имеет право выразить свое возражение Правообладателю против обработки и/или хранения его Персональных данных. Такое возражение может быть выражено следующим образом:</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><em><span style="font-size:16px;color:black;">Запрос должен быть направлен Правообладателю на адрес&nbsp;</span></em><em><span style="font-size:16px;color:black;">info</span></em><em><span style="font-size:16px;color:black;">@</span></em><em><span style="font-size:16px;color:black;">nonhuman</span></em><em><span style="font-size:16px;color:black;">.</span></em><em><span style="font-size:16px;color:black;">ru</span></em></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Запрос на получение информации о персональных данных</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Если у Пользователя возникают вопросы, связанные с порядком применения или использования настоящий Политики, порядком и/или способом обработки Персональных данных, Пользователь может задать такой вопрос следующим образом:</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><em><span style="font-size:16px;color:black;">По любым вопросам к Правообладателю писать на адреc:&nbsp;</span></em><em><span style="font-size:16px;color:black;">info</span></em><em><span style="font-size:16px;color:black;">@</span></em><em><span style="font-size:16px;color:black;">nonhuman</span></em><em><span style="font-size:16px;color:black;">.</span></em><em><span style="font-size:16px;color:black;">ru</span></em></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Изменение (обновление, дополнение, корректировка) или удалении персональных данных</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Пользователь имеет право в любой момент самостоятельно изменить или удалить свои Персональные данные, за исключением случаев, когда такое изменение или удаление может привести <strong>(1)</strong> к нарушению правил настоящей Политики; или <strong>(2)</strong> к нарушению Закона; или <strong>(3)</strong> характер таких Персональных данных является доказательством в каком-либо судебном процессе, возникшем между Правообладателем и Пользователем. Для этого Пользователю требуется удалить свой личный аккаунт (профиль) на Сайте.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Правообладатель имеет право в любой момент удалить личный аккаунт/профиль Пользователя, а также все Персональные данные о Пользователе, если он нарушил условия данной Политики и/или Пользовательского соглашения.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;"><br>&nbsp;<br>&nbsp;8. СРОКИ И ПОРЯДОК ХРАНЕНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Хранение осуществляется самостоятельно Правообладателем.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Хранение осуществляется в течение всего срока, необходимого для достижения заявленных целей обработки Персональных данных.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Правообладатель обязуется немедленно после достижения целей обработки Персональных данных уничтожить их или же обезличить.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;"><br>&nbsp;<br>&nbsp;9. ДОСТУП НЕСОВЕРШЕННОЛЕТНИХ К САЙТУ</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Использование Сайта предназначено для лиц старше 18 лет, которые получают к нему доступ только при условии предоставления предварительного согласия на обработку их Персональных данных.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Если Пользователь является несовершеннолетним лицом, в таком случае он должен немедленно прекратить пользоваться Сайтом.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;"><br>&nbsp;<br>&nbsp;10. ПОРЯДОК ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Защита конфиденциальности Персональных данных является первостепенной и важной задачей для Правообладателя. Правообладатель придерживается всех требуемых международных стандартов, правил и рекомендаций по защите Персональных данных.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Правообладатель внедрил ряд технических и организационных методов, направленных на защиту Персональных данных от разглашения или несанкционированного доступа к ним третьих лиц. Для обеспечения сохранности и конфиденциальности получаемых Персональных данных Правообладатель использует следующие средства защиты:</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(1)</span></strong><span style="font-size:16px;color:black;">&nbsp;SSL (Security Sockets Layer)&nbsp;</span><span style="font-size:16px;color:black;">протокол</span><span style="font-size:16px;color:black;">.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(2)</span></strong><span style="font-size:16px;color:black;">&nbsp;Firewalls.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">(3)</span></strong><span style="font-size:16px;color:black;">&nbsp;а также:</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><em><span style="font-size:16px;color:black;">1.Шифрование данных&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <br>&nbsp;2.Защищенная база данных&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; <br>&nbsp;3.Установка пароля</span></em></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;"><br>&nbsp;<br>&nbsp;11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Доступность текста политики для ознакомления</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Пользователи могут ознакомиться с условиями данной Политики по следующей ссылке: https://nonhuman.ru/privacy_policy.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Данная редакция Политики действует от 1 июля 2022 годa.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Изменение и дополнение политики</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Настоящая Политика может быть изменена время от времени. Правообладатель не несет какой-либо ответственности перед Пользователем за изменение условий данной Политики без разрешения и/или согласия Пользователя.</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Пользователь сам обязуется на регулярной основе проверять положения данной Политики на предмет ее возможного изменения или дополнения. Однако, в случае существенного изменения условий данной Политики, Правообладатель обязуется уведомить Пользователей следующим способом:</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:22.5pt;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><em><span style="font-size:16px;color:black;">Автоматическая рассылка на почту указанную в профиле.</span></em></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Применимое законодательство</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Настоящая Политика разработана в соответствие с действующим законодательством о защите персональных данных Российской Федерации, в частности, с нормами Федерального закона от 27 июля 2006 года № 152-ФЗ &quot;О персональных данных&quot; (со всеми дополнениями и изменениями), Федерального закона от 21 июля 2014 года № 242-ФЗ &quot;О внесении изменений в отдельные законодательные акты Российской Федерации в части уточнения порядка обработки персональных данных в информационно-телекоммуникационных сетях&quot; (со всеми дополнениями и изменениями).</span></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><strong><span style="font-size:16px;color:black;">Риск разглашения</span></strong></p>
<p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Helvetica",sans-serif;text-align:justify;'><span style="font-size:16px;color:black;">Вне зависимости от предпринимаемых Правообладателем мер защиты конфиденциальности получаемых персональных данных, Пользователь настоящим считается должным образом ознакомлен с тем, что любая передача Персональных данных в сети Интернет не может быть гарантировано безопасной, а потому Пользователь осуществляет такую передачу на свой собственный риск.</span></p>`;
	return (
		<div
			dangerouslySetInnerHTML={{ __html: htmlraw }}
			className="docs"
			style={{ paddingRight: "5rem", paddingLeft: "5rem" }}
		></div>
	);
}

export default Privacy;
