import React from "react";
import Mrgn from "../../../layout/Mrgn";
import {Link} from "react-router-dom";
import {CREATE_LONGREADS_ADMIN_PATH} from "../../../service/const/paths";

function ArticlePanel() {
    return (
        <>
            <Mrgn/>
            <section className="max-w-[1200px] m-auto">
                <div className="grid grid-cols-3">
                    <Link to={`${CREATE_LONGREADS_ADMIN_PATH}?type=2`}
                          className="bg-zinc-900 border border-zinc-600 rounded hover:bg-zinc-700 cursor-pointer transition-all p-4 text-center">
                        <h6 className="text-2xl">+ Создать издание</h6>
                    </Link>
                </div>
            </section>
        </>
    );
}

export default ArticlePanel;
