import Mrgn from "../../layout/Mrgn";
import "../../styles/componentStyles/longreads.css";
import LongreadCard from "../../stories/longreads/LongreadCard";
import {useEffect, useState} from "react";
import {getNewLongreads, TLongread} from "../../service/api/longreadsApi";
import {BIG_LONGREADS_PATH, MARKDOWN_PATH_KEY} from "../articles/longreadsConsts";
import { API } from "../../service/const/api";

function ContainerBlogs() {
	const [newLongreads, setNewLongreads] = useState<TLongread[] | null>(null);

	useEffect(() => {
		const get = async () => {
			try {
				const res = await getNewLongreads();
				let newArr = []
				if (Array.isArray(res.data)) {
					newArr = res.data.filter(el=>!el[MARKDOWN_PATH_KEY]?.includes(BIG_LONGREADS_PATH))
				}
				setNewLongreads(newArr);
			} catch (err) {
				console.log(err);
			}
		};
		get();
	}, []);

	const newLongreadsCards = () => {
		if (newLongreads) {
			return newLongreads?.map((longread) => (
				<LongreadCard
					img={`${API}/${longread.pathToImage}`}
					name={longread.title}
					to={longread.url}
					descr={longread.description}
					key={`longreads/${longread.url}`}
				/>
			));
		}
		return <></>;
	};
	console.log(newLongreads);


	return (
		<>
			<Mrgn />
			<div className="longreads-container">
				{
					<>
						{newLongreadsCards()}
					</>
				}
			</div>
		</>
	);
}

export default ContainerBlogs;
