import React from 'react';

const PageHeader = (props: any) => {
    return (
        <h1 {...props} style={{fontSize: "2.5em", fontWeight: 300, marginBottom: "1.5em", wordBreak: "break-word", ...props.style}}>
            {props.children}
        </h1>
    );
};

export default PageHeader;