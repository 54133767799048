export const HOME_PATH = `/`
export const LOGIN_PATH = `/login`
export const REGISTER_PATH = `/register`
export const SUCCESS_REGISTER_PATH = `${REGISTER_PATH}/success`
export const SUBSCRIPTION_PATH = `/subscriptions`
export const ACCOUNT_PATH = `/account`
export const CONFIRM_EMAIL_PATH = `${ACCOUNT_PATH}/confirm-email`
export const RESET_PASSWORD_PATH = `/ResetPassword`
export const VALIDATE_PURCHASE_PATH = "/validate-purchase"
export const VALIDATE_SUBSCRIBE_PURCHASE_PATH = "/validate-subscribe-purchase"
export const UNSUBSCRIBE_PATH = "/unsubscribe"
export const REFER_PATH = "/refer"
export const ABOUT_PATH = "/about"
export const ADMIN_PANEL_PATH = "/admin-panel"
export const LONGREADS_ADMIN_PATH = `${ADMIN_PANEL_PATH}/longreads`
export const NEWS_ADMIN_PATH = `${ADMIN_PANEL_PATH}/news`
export const ARTICLES_ADMIN_PATH = `${ADMIN_PANEL_PATH}/articles`
export const CREATE_LONGREADS_ADMIN_PATH = `${ADMIN_PANEL_PATH}/create`
export const FOR_BUSINESS_PATH = `/for-business`
export const NEWS_PATH = '/news'
export const NEWS_ID_PATH = '/news/:url'
export const BLOGS_PATH = '/longreads'
export const BLOG_ID_PATH = '/longreads/:url'
export const ARTICLES_PATH = '/articles'
export const ARTICLES_ID_PATH = '/articles/:url'
export const LONGREAD_PATH = '/longreads/:id'
export const RADIOS_PATH = '/radio'
export const TEST_PATH = `/test`
export const COURSES_PATH = `/courses`
export const BUSINESS_IN_THE_ERA_OF_SMART_TECHNOLOGIES_PATH = `${COURSES_PATH}/busines-in-the-era-of-smart-technologies`
export const CITY_OUTSIDE_PATH = `${COURSES_PATH}/city-outside`
export const HOW_READ_PEOPLE_PATH = `${COURSES_PATH}/how-read-the-people`
export const CRISES_OF_CIVILIZATION_PATH = `${COURSES_PATH}/crises-of-civilization`
export const PICTURE_BY_DIGIT_PATH = `${COURSES_PATH}/picture-by-digit`
export const ART_OF_DESIGN_AND_ARCHITECTURE_PATH = `${COURSES_PATH}/art-of-design-and-architecture`
export const CUBED_ECONOMY_PATH = `${COURSES_PATH}/cubed-economy`
export const WAR_AND_UNINTELLIGENT_MACHINES_PATH = `${COURSES_PATH}/war-and-unintelligent-machines`
export const SECRETS_OF_THE_UNIVERSE = `${COURSES_PATH}/secrets-of-the-universe`
export const ACTOR_NETWORK_THEORY_PATH = `${COURSES_PATH}/actor-network-theory`
export const HOW_IS_SOCIETY_ORGANIZED_PATH = `${COURSES_PATH}/how-is-society-organized`
export const HOW_THE_WORLD_WORKS_PATH = `${COURSES_PATH}/how-the-world-works`
export const DIGITAL_MAGNATE_PATH = `${COURSES_PATH}/digital-magnate`
export const ASIDE_MIRROR_PATH = `${COURSES_PATH}/aside-mirror`
export const ASIDE_MIRROR_PRO_PATH = `${COURSES_PATH}/aside-mirror-pro`
export const PRIVACY_POLICY_PATH = `/privacy_policy`
export const OFERTA_PATH = `/oferta`

export const LANDING_WELCOME_BOT_ONE = `/welcome_nys_bot_one`
export const LANDING_WELCOME_BOT_TWO = `/welcome_nys_bot_two`
export const LANDING_WELCOME_CHANNEL_ONE = `/welcome_nys_channel_one`
export const LANDING_WELCOME_CHANNEL_TWO = `/welcome_nys_channel_two`
