import axios from "axios";
import {USER_API} from "../const/api";

const articlesApi = axios.create({
    baseURL: USER_API,
});

export const getNews = async () => {
    return await articlesApi.get("getNewsF").catch((e) => {
        console.log(e)
    })
};

export const getNewByUrl = async (URL: string) => {
    return await articlesApi.get(
        `getNewsF?URL=${URL}`
    );
};
