import Mrgn from "../../layout/Mrgn";
import "../../styles/componentStyles/longreads.css";
import {useEffect, useState} from "react";
import {TLongread} from "../../service/api/longreadsApi";
import LongreadCard from "../../stories/longreads/LongreadCard";
import HTwo from "../../components/headers/HTwo";
import {API} from "../../service/const/api";
import {getArticles} from "../../service/api/articlesApi";

function ContainerArticles() {
    const [newLongreads, setNewLongreads] = useState<TLongread[] | null>(null);

    useEffect(() => {
        getArticles().then((res) => {
            setNewLongreads(res?.data)
        })
    }, []);

    return (
        <div>
            <Mrgn/>
            <HTwo>Статьи</HTwo>
            <div className="longreads-container">
                {
                    (Array.isArray(newLongreads) && newLongreads?.map((longread: TLongread) => (
                        <LongreadCard
                            img={`${API}/${longread.pathToImage}`}
                            name={longread.title}
                            to={longread.url}
                            descr={longread.description}
                            key={`longreads/${longread.url}`}
                        />
                    )))
                }
            </div>
        </div>
    );
}

export default ContainerArticles;
