import React from 'react';
import Button from "../../../components/button/Button";
import {buyTinkoffSubscribe} from "../../../service/api/subscribeApi";
import {useNavigate} from "react-router-dom";

const BuySubscribe = (props: any) => {
    const navigate = useNavigate()
    const {subscribe} = props

    const buySubscribe = async () => {
        if (!localStorage.getItem("token")) {
            navigate("/account");
        } else {
            const response = await buyTinkoffSubscribe(
                localStorage.getItem("token"),
                subscribe?.subscribeId
            );
            if (response?.data) {
                if (response.data) {
                    window.location.replace(response.data);
                }
            }
        }
    };

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Button onClick={buySubscribe} style={{marginTop: 16}} text={"Оформить подписку"}/>
        </div>
    );
};

export default BuySubscribe;
