import "../../../styles/componentStyles/coursesPage/course.css";
import {fixImgSrc, getCoursesPage, getPurchasedBlocks} from "../../../service/api/coursesApi";
import React, {useContext, useEffect, useRef, useState} from "react";
import useCourseInfo from "../../../service/hooks/useCourseInfo"; //custom hook
import CoursePlayer from "../CoursePlayer";
import AuthContext from "../../../service/context/AuthProvider"; //if auth token
import BuyCourse from "../BuyCourse";
import {Link} from "react-router-dom";
import ChatLink from "../../../etc/ChatLink";
import useSetMeta from "../../../service/hooks/useSetMeta";
import ChatScreen from "../components/ChatScreen";
import ReviewsCarousel from "../../../widgets/reviews/ReviewsCarousel";
import {buyTinkoff} from "../../../service/api/buyApi";
import FormRegister from "../../../stories/FormRegister";
import review_1 from "./img/review_1.png"
import review_2 from "./img/review_2.png"
import review_3 from "./img/review_3.png"

function Civilization() {
	const ref = useRef<HTMLDivElement>(null)
	const { auth }: any = useContext(AuthContext);

	useSetMeta({
		title: "Как пережить кризис?",
		description: "Как ментально обезопасить себя в неспокойное время? Как избавиться от стресса и увидеть новые возможности? - Разбираемся с опытным психологом!\n"
	})

	const reviews = [review_1, review_2, review_3]

	const buyCourse = async () => {
		if (!localStorage.getItem("token")) {
			if (ref?.current) {
				ref.current?.scrollIntoView({behavior: "smooth", block: "center"});
			}
		} else {
			const response = await buyTinkoff(
				localStorage.getItem("token"),
				COURSEURL
			);
			if (response?.data) {
				if (response.data) {
					window.location.replace(response.data);
				}
			}
		}
	};

	// console.log(showPlayer);
	const COURSEURL = "crises-of-civilization"; //* CHANGE THIS WHEN COPY PASTING
	const LATIN = [
		"I",
		"II",
		"III",
		"IV",
		"V",
		"VI",
		"VII",
		"VIII",
		"IX",
		"X",
		"XI",
		"XII",
		"XIII",
		"XIV",
		"XV",
	];

	const [courses, setCourses] = useState<ICoursesPage | []>([]);

	const [course, setCourse] = useState<any>({});

	interface ICoursesPage {
		courseId: number;
		courseName: string;
		courseUrlid: string;
		currentPrice: any;
		description: string;
		oldPrice: any;
		srcPicturePage: string;
	}

	useEffect(() => {
		const fetchCourses = async () => {
			const responseCourses = await getCoursesPage();
			// console.log(responseCourses);
			if (responseCourses.status === 200) {
				setCourses(responseCourses.data);
				setCourse(
					await responseCourses.data.filter((course: ICoursesPage) => {
						return course.courseUrlid === COURSEURL;
					})[0]
				);
			}
		};
		fetchCourses();
	}, []);

	// console.log(course);

	//* getting button names and info (not content)
	const [courseInfo] = useCourseInfo(COURSEURL);
	// console.log(courseInfo);

	//*purchased button

	const [purchasedBlocks, setPurchasedBlocks] = useState<any>(null);

	useEffect(() => {
		const fetchPurchasedBlocks = async () => {
			if (localStorage.getItem("token")) {
				//!only if autherized
				const response = await getPurchasedBlocks(
					localStorage.getItem("token"),
					COURSEURL
				);
				if (response.data.error === "Курс не был куплен") {
					setPurchasedBlocks(null);
					return;
				}
				if (response.data) {
					setPurchasedBlocks(response.data);
					// console.log(response.data);
				}
			} else return;
		};
		try {
			fetchPurchasedBlocks();
		} catch {
			console.log("oops");
		}
	}, []);

	let showPlayer = false;
	if (auth && localStorage.getItem("token") && purchasedBlocks) {
		showPlayer = true;
	}

	function CourseLanding() {
		return (
			<>
				<div className="course-description">
					<p className="descr-string">Описание курса</p>
					<p className="descr-text-main-1">
						В центре курса «Кризисы цивилизации» находится личность человека,
						претерпевающая изменения под влиянием различных глобальных
						факторов, вроде технологического развития и глобальных кризисов.
					</p>
					<div className="descr-mid-container">
						<div className="descr-how-many">
							<h2>5</h2>
							<p>практических лекций</p>
						</div>
						<div className="descr-who-container">
							<div className="descr-who">
								<img src="../faces/maria_shpan.png" alt="" />
								<div>
									<p className="descr-who-reads">курс читает</p>
									<h2 className="descr-who-name">МАРИЯ ШПАН</h2>
									<p className="descr-who-job">практикующий психолог</p>
								</div>
							</div>
						</div>
					</div>
					<p className="descr-text-main-2">
						{courseInfo?.secondDescription}
					</p>
					<p className="descr-what-you-learn-p">Что вы узнаете на курсе?</p>
					<div className="descr-what-you-learn-container">
						<div className="descr-what-you-learn-text">
							<p>
								какие навыки будут востребованы в новой технологической
								реальности
							</p>
						</div>
						<div className="descr-what-you-learn-text">
							<p>
								как победить тревожность и справиться с постоянно давящим
								стрессом
							</p>
						</div>
						<div className="descr-what-you-learn-text">
							<p>
								как правильно разработать индивидуальный план подготовки к
								будущему миру
							</p>
						</div>
						<div className="descr-what-you-learn-text">
							<p>
								как грамотно оценить риски, утвердить правильный план действий
								на будущее и придерживаться его
							</p>
						</div>
						<div></div>
						<div className="descr-what-you-learn-text">
							<p>
								как адаптироваться к кризисным периодам, перестать паниковать
								и жить дальше
							</p>
						</div>
					</div>
				</div>
				<div className="present">
					<div>
						<h2>подарок</h2>
						<p>запись онлайн-вебинара от Марины,</p>
						<p>
							посвященного истории великих психологов, их подходам и жизням{" "}
						</p>
					</div>
					<div>
						<img src="../gift_icon.png" alt="" />
					</div>
				</div>

				<ChatScreen type={"psycho"}/>

				<div className="course-program">
					<h2>Программа курса</h2>
					<div className="course-program-blocks">
						{courseInfo.p ? (
							courseInfo.p.b.map((element: any, index: any) => {
								let date = "";
								if (element.blockReleaseDate) {
									date = element.blockReleaseDate;
									date.replace("T", " ");
								}
								return (
									<div key={element["blockId"]}>
										<h3 className="block-name">
											{LATIN[index]}&nbsp;&nbsp;&nbsp;
											{element["blockName"]}&nbsp;&nbsp;
											<span className="course-block-date">
													{date &&
														date
															.replace("T", " ")
															.slice(0, -3)
															.replace("-", "/")
															.replace("-", "/")}
												</span>
										</h3>
										<div className="subblocks">
											{element.i.map((elementInner: any) => {
												return (
													<p key={elementInner["blockInfoBlockInfoId"]}>
														— {elementInner["blockInfoBlockInfoName"]}
													</p>
												);
											})}
										</div>
									</div>
								);
							})
						) : (
							<div>Загрузка...</div>
						)}
					</div>
				</div>
				<div className="buy-course-end">
					<BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
				</div>

				<ReviewsCarousel maxCount={3} reviews={reviews}
				/>
			</>
		)
	}

	return (
		<>
			<div className="course-nav">
				<Link to="/">
					<img width="150px" height="85px" src="../../logo.png" alt="" />
				</Link>
			</div>
			{course.courseId ? (
				<div className="course-container">
					<div
						className="course-hero"
						style={{
							backgroundImage: `url(${fixImgSrc(
								"files/course-pictures/crises-of-civilization-2.png"
							)})`,
						}}
					>
						<div className="course-hero-info">
							<p className="online-course-text">Онлайн курс</p>
							<h1>{course.courseName.toUpperCase()}</h1>
							<BuyCourse COURSEURL={COURSEURL} buyCourse={buyCourse}/>
						</div>
					</div>
					{
						!localStorage.getItem("token") &&
						<div style={{marginTop: 32}} ref={ref}>
							<div  className="p-2 text-center my-4 md:max-w-[1000px] m-auto">
								Для приобритения курса зарегестрируйтесь на сайте
							</div>
							<FormRegister discount={courseInfo?.p?.courseOldPrice}
										  courseOldPrice={courseInfo?.p?.courseOldPrice}
										  courseCurrentPrice={courseInfo?.p?.courseCurrentPrice}
										  buyFunc={buyCourse}/>
						</div>
					}
					{showPlayer && (
						<>
							<CoursePlayer
								purchasedBlocks={purchasedBlocks}
								courseURLId={COURSEURL}
							/>
							<ChatLink
								chatName="Чат “Цивилизационные кризисы. Психология на (non)human”"
								chatLink="https://t.me/+VNKc2O_b2wNlYTQy"
							/>
						</>
					)}

					{!showPlayer && <CourseLanding/>}
				</div>
			) : (
				<div></div>
			)}
		</>
	);
}

export default Civilization;
