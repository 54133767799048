import React from 'react';
import "../templateLanding.css"

export const MiniCard = (props) => {
    const {text} = props

    return (
        <div className={"mini_card"}>
            <p>{text}</p>
        </div>
    );
};
