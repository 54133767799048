import axios from "axios";
import {ILoginUser, IRegisterNewUserFetch} from "../types/interfaces";
import {AUTH_API, USER_API} from "../const/api";

const authApi = axios.create({
    baseURL: AUTH_API,
});

export const registerNewUser = async (registeredUser: IRegisterNewUserFetch) => {
    try {
        const response = await authApi.post(
            "/Register",
            JSON.stringify(registeredUser),
            {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
            }
        );
        return {
            status: response.status,
            data: response.data.authenticated,
        };
    } catch (error: any) {
        return {
            status: error.response.status,
            data: error.response.data.error
        };
    }
};

interface ISuccessfulLogin {
    token: string;
    refreshToken: string;
}

export const loginUser = async (loginnedUser: ILoginUser) => {
    try {
        const response = await authApi.post<ISuccessfulLogin>(
            "/Login",
            JSON.stringify(loginnedUser),
            {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
            }
        );
        return response.data;
    } catch (error: any) {
        return error.response.data;
    }
};

export const resetPassEmailConfirm = async (email: string) => {
    try {
        return await authApi.post(
            "ResetPassword",
            JSON.stringify({
                email: email,
            }),
            {
                headers: {"Content-Type": "application/json"},
            }
        );
    } catch (error: any) {
        return error.response;
    }
};

export const resetPassNewPass = async (
    userId: string,
    password: string,
    confirmPassword: string,
    code: string
) => {
    try {
        return await authApi.post(
            "/ResetPasswordConfirmation",
            JSON.stringify({
                userId,
                password,
                confirmPassword,
                code,
            }),
            {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
            }
        );
    } catch (error: any) {
        return error.response;
    }
};

export const getUser = async (token: string) => {
    try {
        const response = await authApi.get(`/User?token=${token}`);
        return response.data;
    } catch (error: any) {
        // console.log(error);
    }
};

export const generateReferLink = async () => {
    const token = localStorage.getItem("token");
    return await axios.get(
        `${USER_API}/GenerateReferLink?token=${token}`
    );
};

interface IEmailConf {
    userId: string | null;
    code: string | null;
}

export const confirmEmail = async (userStuff: IEmailConf) => {
    const response = await authApi.post(
        "/ConfirmEmail",
        JSON.stringify(userStuff),
        {
            withCredentials: true,
            headers: {"Content-Type": "application/json"},
        }
    );
    return response.data;
};
