interface IProps {
	chatLink: string;
	chatName: string;
}

function ChatLink({ chatLink, chatName }: IProps) {
	return (
		<div className="chat-link-container">
			<a className="chat-link-inner" href={chatLink}>
				<img src="../../socials/tg_icon.png" alt="" />
				<p>{chatName}</p>
			</a>
		</div>
	);
}

export default ChatLink;
