import React from 'react';
import {Helmet} from "react-helmet-async";

type SeoHelmetProps = {
    title: string,
    description: string,
    url: string,
    img: string,
    script?: string,
    noscript?: string
}

const DOMAIN_URL = "https://nysacademy.ru"

export const SeoHelmet = (props: SeoHelmetProps) => {
    const {title, description, url, img, script, noscript} = props

    return (
        <Helmet
            title={title}
            meta={[
                {name: "description", content: description},
                {property: "og:title", content: title},
                {property: "og:image", content: img},
                {property: "og:description", content: description},
                {property: "og:url", content: `${DOMAIN_URL}${url}`},
                {property: "twitter:title", content: title},
                {property: "twitter:description", content: description},
            ]}
        >
            {
                script && <script type="text/javascript">
                    {script}
                </script>
            }

            {
                noscript &&
                <noscript>
                    {noscript}
                </noscript>
            }
        </Helmet>
    );
};

