export const getFormLabels = (type: number) => {

    const form: any = {
        0: {
            title: "Создание Новости",
            name: "новости",
        },
        1: {
            title: "Создание Блога",
            name: "блога",
        },
        2: {
            title: "Создание Издания",
            name: "издания",
        }
    }

    return form[type]
}
